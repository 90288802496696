.c-staker-profile {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row-reverse;
    padding-top: 1vw;

    &-avatar {
        height: 100%;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-right: 5vw;

        &-dropzone {
            display: grid;
            place-items: center;
            width: 15vw;
            height: 15vw;
            background: #222;
            clip-path: circle();
            cursor: pointer;

            i {
                font-size: clamp(1.4rem, 1.5vw, 2rem);
            }
        }

        &-photo {
            position: absolute;
            z-index: 10;
            width: 15vw;
            height: 15vw;
            clip-path: circle();

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        img {
            position: absolute;
            object-fit: contain;
            max-width: 21vw;
            z-index: 100;
        }
    }

    &-infos {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-end;
        margin-right: 5vw;

        p {
            font-size: clamp(1.2rem, 1.5vw, 2.5rem);
            font-family: gilroy-thin;
            text-align: end;
            white-space: nowrap;
        }

        h1 {
            font-size: clamp(2.6rem, 4vw, 7.5rem);
            letter-spacing: .4vw;
        }

        &-rewards {
            display: flex;
            align-items: center;
            justify-content: space-between;
            white-space: nowrap;
            margin-bottom: 2vw;

            h3 {
                font-size: clamp(1rem, 1.2vw, 2rem);
            }

            i:nth-child(1) {
                color: $yellow;
            }

            i {
                display: flex;
                align-items: center;
                color: $pink;
                font-size: clamp(1rem, 1.2vw, 2rem);
                margin-left: 5vw;

                p {
                    font-size: clamp(1rem, 1.2vw, 2rem);
                    margin-left: 5px;
                    color: inherit;
                }
            }
        }

        &-social {
            display: flex;
            align-items: center;
            height: 15%;

            i {
                font-size: clamp(1.2rem, 1.35vw, 1.8rem);
                color: $pink2;
                cursor: pointer;
                margin-right: .8vw;
                transition: $cubic-main;

                &:hover {
                    color: $lightblue;
                    filter: drop-shadow(2px 4px 8px $lightblue);
                }
            }
        }
    }
}