.meusStakersPage {
  width: 100%;
  height: 100%;

  .meusStakersTop {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 50%;
  }

  .meusStakersBotton {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 50%;

    .meusStakersBottonLeft {
      width: 50%;
      height: 100%;
    }

    .pista {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .meusStakersBottonRight {
      display: flex;
      justify-content: center;
      width: 50%;
      height: 90%;
    }
  }
}
