.EventPage {
  width: 100%;
  height: 100%;
  display: flex;
  font-family: gilroy;
}

.Event_nameNavBar {
  position: absolute;
  top: 12vh;
  left: 4vw;
  font-size: 1.4rem;
  font-weight: bold;
  letter-spacing: 1px;
}

.Event_Left {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.Event_Right {
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.menuEvent {
  height: 50%;
}

.leftBar {
  width: 85%;
  height: 85%;
}

.rightBar {
  width: 85%;
  height: 85%;
}

.EventProfile {
  width: 100%;
  height: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.logoEvent {
  width: 100%;
  height: 30%;
  display: flex;
  align-items: center;
}

.event_img {
  background: #ffb740;
  width: 5.1rem;
  height: 5.1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 15%;
}

.event_img img {
  max-width: 85%;
  object-fit: contain;
}

.event_infoSco {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 1.5rem;
}

.gradientName {
  font-size: 1.7rem;
  font-family: Gilroy-Bold;
  -webkit-transform: scale(1, 0.9);
  /* Safari and Chrome */
  background: -webkit-linear-gradient(left, #2ac1d8, #ff28f1);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 5px;
}

.event_results {
  font-size: 1.2rem;
  font-family: gilroy-thin;
}

.event_results b {
  font-weight: bold;
  font-family: gilroy-medium;
  letter-spacing: 1px;
}

.event_description {
  width: 100%;
  height: 40%;
  max-height: 40%;
  font-size: 1.6rem;
  line-height: 1.2;
  font-family: Gilroy-thin;
  overflow-y: auto;
  overflow-x: hidden;
}

.DateandBtn {
  width: 95%;
  height: 30%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.event_Date {
  font-family: gilroy-medium;
  font-size: 1.5rem;
  font-weight: bold;
  letter-spacing: 1px;
  margin-bottom: 6px;
}

.event_Hours {
  font-family: gilroy-thin;
  font-size: 1.3rem;
  letter-spacing: 1px;
  margin-top: 6px;
}

.event_subBtn {
  width: 22rem;
  height: 3.5rem;
  background: transparent;
  border-radius: 1.1rem;
  font-size: 1.2rem;
  font-family: gilroy-medium;
  font-weight: bold;
  letter-spacing: 1px;
  outline: none;
  border: 4px solid var(--lightblue);
  cursor: pointer;
  color: #fff;
  transition: 0.2s;
  user-select: none;
}

.event_subBtn a,
b {
  font-family: gilroy-thin;
  font-style: normal;
  color: #fff;
  /* transition: 0.2s; */
}

.event_subBtn b:hover {
  text-shadow: 0 0 7px #ffb740;
  color: #ffb740;
}

.event_subBtn:hover {
  box-shadow: 0 0 7px #2ac1d8, inset 0 0 7px #2ac1d8;
  color: #ff28f1;
}

/* .event_subBtn:focus {
  box-shadow: 0 0 7px #2ac1d8, inset 0 0 7px #2ac1d8;
} */

.deckSelect {
  margin-top: 10px;
}

.event_subBtn:active {
  transform: scale(0.97);
}

.event_subBtn_disable {
  background: rgba(0, 0, 0, 0.4);
  color: var(--purple);
  font-size: 1.2rem;
  width: 22rem;
  height: 3.5rem;
  border-radius: 1.1rem;
  font-family: gilroy-bold;
  letter-spacing: 1px;
  outline: none;
  border: none;
}

/* MODAL */

.formSub_container {
  max-width: 480px;
}

.formSub_container h1 {
  font-family: gilroy-thin;
  font-size: 1.35rem;
  opacity: 0.9;
  letter-spacing: 0.5px;
}

.formSub_container h2 {
  color: var(--pink);
  font-family: Gilroy-medium;
  font-weight: bold;
  font-size: 1.06rem;
  letter-spacing: 0.5px;
}

.formSub_container .formSub__descSub {
  font-family: gilroy-thin;
  margin: 10px 0px;
  opacity: 0.9;
  font-size: 1rem;
  letter-spacing: 0.5px;
}

.formSub_top {
  font-family: Gilroy;
  font-weight: bold;
  letter-spacing: 1px;
  margin-bottom: 2px;
}

.formSub_bottom {
  width: 100%;
  margin-top: 20px;
  font-family: gilroy-thin;
  opacity: 0.85;
}

.formSub_bottom .formSub_bottom-sale {
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-size: 1.1rem;
  margin-bottom: 16px;
}

.formSub_bottom .formSub_bottom-price {
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-size: 1.1rem;
  margin-bottom: 35px;
}

.formSub_bottom .formSub_infos {
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-size: 1.1rem;
}

.formSub_btns {
  margin-top: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.event_confirm_btn-disabled {
  height: 52px;
  width: 216px;
  border-radius: 15px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  box-sizing: border-box;
  border: none;
  margin-right: 5px;
  color: white;
  font-size: 1.25rem;
  font-family: Gilroy-Bold;
  letter-spacing: 1px;
  cursor: pointer;
  transition: 0.2s;
  background-color: grey;
  cursor: not-allowed;
  user-select: none;
}
.event_confirm_btn {
  height: 52px;
  width: 216px;
  border-radius: 15px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  background: black;
  box-sizing: border-box;
  border: none;
  margin-right: 5px;
  color: white;
  font-size: 1.25rem;
  font-family: Gilroy-Bold;
  letter-spacing: 1px;
  cursor: pointer;
  transition: 0.2s;
}

.event_confirm_btn:hover {
  color: #2ac1d8;
  text-shadow: 0 0 10px #2ac1d8;
}

.event_confirm_btn::before {
  border-radius: 18px;
  content: "";
  background-image: linear-gradient(to right, var(--lightblue), var(--purple), var(--pink) 80%);
  top: -4px;
  left: -4px;
  bottom: -4px;
  right: -4px;
  position: absolute;
  z-index: -1;
}

.event_cancel_btn {
  color: rgb(200, 0, 250);
  font-size: 1.25rem;
  font-family: gilroy-bold;
  letter-spacing: 0.5px;
  height: 60px;
  width: 220px;
  background: transparent;
  border-radius: 18px;
  box-shadow: inset 0px 0px 0px 4px var(--purple);
  border: none;
  cursor: pointer;
  transition: 0.2s;
}

.event_cancel_btn:hover {
  text-shadow: 0 0 10px rgb(200, 0, 250);
}
