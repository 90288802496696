%title-sing-in-up {
  width: 100%;
  height: 10vh;
  font-size: clamp(2rem, 2vw, 2.7rem);
  letter-spacing: 2px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  text-transform: uppercase;
}

.modal {
  width: 100%;
  height: 100%;
  background-color: transparent;
  overflow-y: auto;
}

.FormGrid {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.sing-up {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 100%;
  position: relative;

  @include mobile {
    width: 90%;
  }

  @include extra-small-mobile {
    justify-content: flex-start;
  }
}

.sing-in {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 100%;
  position: relative;

  @include mobile {
    width: 90%;
  }
}

.sing-up h2 {
  @extend %title-sing-in-up;
}

.sing-in h2 {
  @extend %title-sing-in-up;
}

.formSingup {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 70%;
  padding-top: 5%;

  @include mobile {
    width: 100%;
  }
}

.formSingin {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 5%;
  height: 70%;

  @include mobile {
    width: 100%;
  }
}

.checkbox-div {
  width: 100%;
  height: 10%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
  font-size: clamp(0.8rem, 1.2vw, 1.4rem);
  white-space: nowrap;

  &_policy-box {
    letter-spacing: 1px;

    .link-policy {
      color: $lightblue !important;
      text-decoration: none !important;
      position: relative;

      &::after {
        content: "";
        width: 100%;
        height: 1px;
        background-color: currentColor;
        position: absolute;
        bottom: 0;
        left: 0;
        transform: scaleX(0);
        transform-origin: left;
        transition: transform 125ms ease-in-out;
      }

      &:hover::after {
        transform: scaleX(1);
      }
    }
  }

  &_remember {
    letter-spacing: 1px;
  }
}

.link-forgot-pass {
  color: $lightblue !important;
  white-space: nowrap;
  letter-spacing: 1px;
}
