.topPlayers {
  width: 100%;
  height: 100%;

  &__item {
    width: 100%;
    height: 7rem;
    margin: 10px 0;
    display: inline-flex;
    align-items: center;
    border-radius: 15px;
    transition: 0.2s;
    cursor: pointer;
    position: relative;

    &__avatar {
      height: 100%;
      width: 180px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: relative;

      .avatar__photo {
        background-image: url(../../assets/img/borda_avatar3.webp);
        img {
          width: 60px;
          height: 60px;
          object-fit: contain;
          border-radius: 50%;
          position: relative;
          /* left: 40%; */
        }
        span {
          font-style: italic;
          font-size: 1.3rem;
          letter-spacing: 4px;
          font-family: gilroy-bold;
          background: linear-gradient(to right, var(--lightblue), var(--pink2));
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          position: absolute;
          bottom: 0;
          left: 25px;
        }
      }
    }
  }

  &__item:hover {
    background: rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    box-shadow: 5px 9px 13px 0px rgba(0, 0, 0, 0.3) !important;
    transform: scale(1.01);
  }
}
