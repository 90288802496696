.page-create-acc {
  display: flex;
  width: 100vw;
  height: 100vh;
  position: relative;
  overflow: hidden;
  align-items: center;
  justify-content: center;

  .exit-button {
    position: absolute;
    top: 1.5vw;
    right: 1.5vw;
    letter-spacing: 0;
    font-weight: normal;
    text-transform: lowercase;
    width: 2.2vw;
    min-width: 2rem;
    height: 2.2vw;
    min-height: 2rem;
    border-radius: 50%;
    padding: 0;
    display: grid;
    place-items: center;

    p {
      color: $main-color;
      position: absolute;
    }
  }

  &-right {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow-y: auto;
    overflow-x: hidden;
    background: rgba(0, 0, 0, 0.9);

    @include extra-large-device {
      width: 100%;
      padding: 2rem 0;
    }

    h1 {
      display: none;
      width: 85%;
      line-height: 1.16;
    }

    &-form {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      @include extra-large-device {
        justify-content: flex-start;
      }

      @include mobile {
        width: 95%;
      }

      a {
        margin-top: 1rem;
      }
    }
  }
}
