.c-evo-profile {
    display: flex;
    position: relative;
    z-index: 1000;
    height: 100%;
    width: 100%;

    button {
        display: flex;
        align-items: center;
        font-size: clamp(1rem, 1.2vw, 2rem);
        letter-spacing: .2vw;
        position: absolute;
        top: 3vw;
        right: .5vw;
        z-index: 100;
        background: transparent;
        border: none;
        cursor: pointer;
        outline: none;

        @include mobile {
            margin-right: 1.5vw;
        }

        i {
            color: $pink;
            font-size: clamp(1rem, 1.5vw, 2rem);
            margin-left: 5px;
            transition: $cubic-main;
        }

        &:hover i {
            color: $pink2;
        }

        &:active {
            transform: scale(.9);
        }
    }

    &-avatar {
        height: 100%;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 1vw;

        @include mobile {
            margin: 0 2vw;
        }

        &-dropzone {
            display: grid;
            place-items: center;
            border-radius: 2vw;
            width: clamp(8.2rem, 16vw, 20.2rem);
            height: clamp(6.8rem, 14vw, 17.8rem);
            background: #222;
            cursor: pointer;

            @include extra-small-mobile {
                width: clamp(6.2rem, 16vw, 20.2rem);
                height: clamp(4.8rem, 14vw, 17.8rem);
            }

            i {
                font-size: clamp(1.4rem, 1.5vw, 2rem);
            }
        }

        img {
            object-fit: contain;
            border-radius: 2vw;
            width: clamp(8.2rem, 16vw, 20.2rem);
            height: clamp(6.8rem, 14vw, 17.8rem);
            z-index: 100;

            @include extra-small-mobile {
                width: clamp(6.2rem, 16vw, 20.2rem);
                height: clamp(4.8rem, 14vw, 17.8rem);
            }
        }
    }

    &-infos {
        display: flex;
        flex-direction: column;
        justify-content: center;

        &-name {
            display: flex;
            align-items: center;
            position: relative;
            margin-bottom: 3vh;

            @include mobile {
                margin-bottom: 1.2vh;
            }

            h1 {
                font-size: clamp(2.2rem, 2.8vw, 3.8rem);
                letter-spacing: .4vw;
                text-overflow: clip ellipsis;

                @include mobile {
                    font-size: clamp(1.6rem, 2.8vw, 3.8rem);
                }

                @include extra-small-mobile {
                    font-size: clamp(1.2rem, 2.8vw, 3.8rem);
                }
            }

            img {
                height: clamp(1rem, 1.7vw, 3rem);
                padding-left: .4vw;
            }
        }

        &-social {
            display: flex;
            align-items: center;

            i {
                font-size: clamp(1rem, 1.35vw, 1.8rem);
                color: $pink2;
                cursor: pointer;
                margin-right: .8vw;
                transition: $cubic-main;

                @include mobile {
                    margin-right: 2vw;
                    font-size: clamp(.9rem, 1.35vw, 1.8rem);
                }

                &:hover {
                    color: $lightblue;
                    filter: drop-shadow(2px 4px 8px $lightblue);
                }
            }
        }
    }
}