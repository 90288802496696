.welcome {
  display: flex;
  width: 100%;
  height: 100%;
  overflow: hidden;

  @include large-device {
    flex-direction: column;
    min-height: 600px;
  }

  @include mobile {
    min-height: 400px;
  }
}

/*  left-container  */

.w-left {
  width: 55%;
  height: 100%;

  @include large-device {
    width: 100%;
    height: 50%;
    text-align: center;
  }
}

.w-title {
  padding-left: 10vw;
  padding-top: 4.8vh;

  @include large-device {
    padding-left: 0;
  }

  p {
    font-size: clamp(1rem, 1.4vw, 2rem);
    line-height: 0;
  }

  h1 {
    display: inline-block;
    font-family: Gilroy-medium;
    font-style: italic;
    font-size: clamp(2.2rem, 4vw, 4.6rem);
    letter-spacing: 1vw;
    line-height: 0.9;
    background-image: linear-gradient(to right, $lightblue 15%, $pink 80%);
    background-clip: text;
    background-position: center;
    background-size: cover;
    -webkit-text-fill-color: transparent;
  }
}

.w-logo {
  display: flex;
  align-items: center;
  height: 85%;
  width: 100%;

  &-img {
    background: url(../../assets/img/branca2.webp) no-repeat center;
    background-size: contain;
    width: 100%;
    height: 90%;
  }
}

/*  right-container  */

.w-right {
  width: 45%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  @include large-device {
    width: 100%;
    height: 50%;
    align-items: center;
  }

  &-link {
    display: flex;
    align-items: center;
    overflow: hidden;

    @include large-device {
      overflow: visible;
    }

    @include mobile {
      width: 90%;
    }

    &-line {
      background: $lightblue;
      border-radius: 2px;
      height: 10vh;
      width: 5px;
      margin-right: 20px;

      @include large-device {
        max-height: 7vh;
        min-height: 4rem;
        margin-right: 12px;
      }

      @include extra-small-mobile {
        width: 4px;
      }
    }

    &-txt {
      animation: slide-animation 1.5s ease-in-out;
      text-decoration: none;
      letter-spacing: 2px;

      @include mobile {
        animation: none;
      }

      p {
        font-family: Gilroy-Thin;
        line-height: 1.6;
        font-size: clamp(1rem, 1.4vw, 2rem);
        white-space: nowrap;

        @include mobile {
          font-size: clamp(0.8rem, 1vw, 2rem);
        }

        @include extra-small-mobile {
          font-size: clamp(0.6rem, 1vw, 2rem);
        }
      }

      h3 {
        font-size: clamp(1.4rem, 1.7vw, 3rem);
        font-family: Gilroy-bold;
        font-weight: bold;
        transition: color 125ms ease-in-out;

        @include mobile {
          font-size: clamp(1rem, 1.3vw, 3rem);
        }

        @include extra-small-mobile {
          font-size: clamp(0.8rem, 1.3vw, 3rem);
        }
      }

      &:hover h3 {
        color: $pink;
      }

      &:active h3 {
        color: $pink2;
      }
    }
  }

  &-container-buttons {
    display: flex;
    margin: 6vh 0;

    .translate-y:nth-child(-n + 2) {
      margin-right: 2vw;
    }

    @include extra-small-mobile {
      margin: 4vh 0;
    }
  }
}
