.storePage {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.HeaderStore {
  width: 100%;
  height: 10%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  input {
    width: 37.5rem !important;
  }
}

.bodyStore {
  width: 100%;
  height: 90%;
  display: flex;
  justify-content: center;
}

.bodyStore_items {
  width: 90%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  overflow-y: auto;
  overflow-x: hidden;
}

.cartBox {
  position: absolute;
  right: 2rem;
  z-index: 100;

  i {
    color: $lightblue;
    cursor: pointer;
  }
}

.CartModal {
  position: absolute;
  top: 50px;
  left: -250px;
  margin: 0;
  width: 0px;
  transition: width ease 0.4s;
}

.cartModalOpen {
  width: 300px;
  height: 300px;
  background-color: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(5px);
  border-radius: 12px;
  transition: 200ms ease-in-out;
}

.cartItemCount {
  width: 22px;
  height: 22px;
  position: absolute;
  background-color: $pink;
  border-radius: 50%;
  top: -10px;
  right: -5px;
  display: grid;
  place-items: center;
  text-align: center;
  opacity: 0;
  font-weight: bold;
}

.countAtivo {
  opacity: 1;
}
