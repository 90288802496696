.half-page-player {
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;

  &-profile {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
    min-height: 30vh;
  }

  &-badges {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    min-height: 23vh;
    display: flex;
    justify-content: center;
    // background-color: red;

    p {
      font-size: 1.2rem;
      letter-spacing: 1px;
      line-height: 1;
      font-weight: bold;

      @include mobile {
        font-size: 1rem;
      }
    }

    .hof-button {
      margin-top: 0.5rem;
      padding: 0.4rem;
      font-size: 0.8rem !important;
    }
  }

  &-graphic {
    position: relative;
    width: 100%;
    min-height: 25vh;
    display: flex;
    justify-content: center;
    // margin-left: 20px;
  }
}
