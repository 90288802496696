.page-staker {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  min-height: 600px;
  overflow: hidden;

  .backgroundStaker {
    position: absolute;
    top: 0;
    left: 0;
    background: radial-gradient(52% 52% at 67% 48.55%, #2a004f 8.6%, #180a29 79.39%);
    filter: hue-rotate(70deg);
    margin: 0;
    height: 100%;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    z-index: -1;
  }

  &-top {
    display: flex;
    flex-direction: row-reverse;
    height: 53%;
    width: 100%;

    @include large-device {
      flex-direction: column;
      align-items: center;
    }

    &-left {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      width: 60%;
      padding-left: 5%;
      padding-bottom: 1.5rem;

      @include large-device {
        width: 80%;
      }
    }

    &-right {
      width: 40%;
      height: 100%;
      padding-left: 1.5vw;

      @include large-device {
        width: 80%;
      }

      &-staked {
        width: 100%;
        height: 55%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        h2 {
          font-size: 1.6rem;
          letter-spacing: 1px;
          white-space: nowrap;
          position: relative;

          &::after {
            content: "";
            position: absolute;
            left: 0;
            bottom: -3px;
            width: 100%;
            height: 3px;
            border-radius: 3px;
            background: $lightblue;
            animation: staked-animation 2s ease-in-out alternate infinite;
          }
        }

        span {
          position: relative;
          color: #d3d3d3;
          width: 75%;
          display: flex;
          align-items: flex-end;
          justify-content: flex-end;
          border: none;
          border-radius: 1.2vw;
          background: $lightblue;
          cursor: not-allowed;
          padding: 0 1rem;
          white-space: nowrap;
          font-size: clamp(2.4rem, 7vw, 9rem);
          filter: grayscale(90%);
          opacity: 0.9;

          // p {
          //     font-size: clamp(.7rem, 1.3vw, 2.4rem);
          //     text-transform: uppercase;
          //     padding-bottom: 1vh;
          //     margin: 0 1vw;
          //     white-space: nowrap;
          // }

          i {
            position: absolute;
            right: -0.4vw;
            bottom: -0.4vw;
            font-size: clamp(0.8rem, 1.6vw, 2.4rem);
          }
        }
      }

      &-stake {
        width: 100%;
        height: 45%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        h2 {
          font-size: 1.6rem;
          letter-spacing: 1px;
          position: relative;
          white-space: nowrap;
          margin-right: 1vw;

          &::after {
            content: "";
            position: absolute;
            left: 0;
            bottom: -3px;
            width: 100%;
            height: 3px;
            border-radius: 3px;
            background: $lightblue;
            animation: staked-animation 2s ease-in-out 0.5s alternate infinite;
          }
        }
      }
    }
  }

  &-bottom {
    height: 50%;
    width: 95%;
  }
}
