.item_evoList {
  background: rgba($color: #000000, $alpha: 0.2);
  height: clamp(5rem, 10vh, 8rem);
  width: 98%;
  display: flex;
  border-radius: 24px;
  padding: 0.8rem;
  margin-bottom: 1.5vh;
  cursor: pointer;
  transition: $cubic-main;

  &:hover {
    transform: translateX(20px);
    background: rgba($color: #000000, $alpha: 0.4);
  }

  &-img {
    height: 100%;
    width: 20%;
    margin-right: 1rem;

    img {
      object-fit: cover;
      width: 100%;
      max-height: 100%;
      border-radius: 16px;
    }
  }

  &-infos {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &-name {
      font-size: clamp(1.2rem, 1.6vw, 2.2rem);
    }

    &-points {
      display: flex;
      padding-left: 0.3rem;

      &-item {
        font-size: 1.4rem;
        font-family: gilroy-medium;
        font-weight: bold;
      }
    }
  }
}
