.tronLink {
  margin: 50px;
  background: white;
  // background: white;
  border-radius: 15px;
  padding: 40px;
  margin-bottom: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;

  h1 {
    margin: 0;
    // padding: 2rem;
    margin-bottom: 10px;
    color: black;
  }

  p {
    padding: 0;
    margin: 0;
    text-align: justify;
    font-size: 1.4rem;
    color: black;
  }

  .info {
    flex: 1;
  }

  .logo {
    margin-left: 30px;
  }
}

.tronLink.hover {
  transition: all 200ms ease;
  cursor: pointer;
}

.tronLink:hover {
  transform: scale(1.04);
  cursor: pointer;
  user-select: none;
  box-shadow: 0px 4px 9px 0px rgba(0, 0, 0, 0.12);
}
