.carousel-card {
  height: 70%;
  width: 30%;
  min-width: 350px;
  background-image: url("../../assets/img/bg6.webp");
  background-position: center;
  background-size: cover;
  border: none;
  border-radius: 24px;
  text-align: center;
  letter-spacing: 1px;
  cursor: pointer;

  @include mobile {
    min-width: 265px;
  }

  &-title {
    width: 100%;
    height: 45%;
    padding: 15% 1.5rem 0.8rem 1.5rem;

    h2 {
      font-weight: bold;
      font-family: gilroy-medium;
      font-size: clamp(1.8rem, 2.5vw, 2.2rem);
      text-transform: uppercase;
      word-wrap: break-word;
    }
  }

  &-content {
    width: 100%;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;

    &-data {
      font-size: 1.2rem;
      display: flex;
      align-items: center;
      justify-content: space-around;
      width: 100%;

      @include extra-large-device {
        font-size: 1rem;
      }

      &-box {
        b {
          font-family: gilroy-medium;
          white-space: nowrap;
        }

        b:nth-child(-n + 2) {
          margin-right: 5%;
        }
      }

      span {
        font-size: 1.2rem;
        font-style: italic;
        color: $lightblue;
        filter: contrast(1.5);

        @include extra-large-device {
          font-size: 1rem;
        }

        .fa-clipboard-check {
          color: $lightblue;
          filter: contrast(1.5);
          margin-right: 2px;
        }
      }
    }

    &-line {
      width: 90%;
      height: 1px;
      background-color: $pink2;
      border-radius: 5px;
      margin: 2% 0;
    }

    &-rewards {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0 2rem;

      span {
        font-style: italic;
        white-space: nowrap;

        .fa-users {
          font-size: clamp(1.6rem, 3vw, 2.4rem);
          color: $pink;
        }

        .fa-trophy {
          font-size: clamp(1.6rem, 2.8vw, 2.4rem);
          margin-right: 3px;
          color: $yellow;
        }
      }

      span:nth-child(1) {
        font-size: clamp(1.8rem, 3.5vw, 2.6rem);
        color: $pink;
        margin-bottom: 40%;
      }

      span:nth-child(2) {
        font-size: clamp(1.6rem, 3vw, 2.4rem);
        color: $yellow;
      }
    }
  }

  &-logo {
    display: grid;
    place-items: center;
    width: 5rem;
    height: 5rem;
    background-color: $pink2;
    border: none;
    border-radius: 16px;
    position: absolute;
    bottom: -2.5rem;
    left: 50%;
    z-index: 1000;
    transform: translateX(-50%);

    @include extra-large-device {
      width: 3.8rem;
      height: 3.8rem;
      bottom: -1.9rem;
    }

    img {
      position: absolute;
      display: block;
      object-fit: cover;
      width: 90%;
      max-height: 90%;
      border-radius: 14px;
    }
  }
}

// ------------------------------------------- //
// general configurations //
// ------------------------------------------- //

.swiper-slide-cards {
  width: 300px !important;
  height: 100% !important;
  object-fit: contain;
}

.slider_logoLoja-cards {
  max-width: 90%;
  object-fit: contain;
  z-index: 3000;
}

.EvoIcon-cards {
  position: absolute;
  left: 50%;
  width: 65px;
  height: 65px;
  margin: -35px;
  border-radius: 8px;
  background-color: rgba(0, 0, 0, 0.5);
  transition: 0.1s;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffb740;
  user-select: none;
}

.swipper-slide-img {
  &-deck:hover {
    margin: 5px;
    transform: scale(1.02);
  }
}
