.ui-form {
  width: 100%;
  padding: 5vh 2vw;

  &-grid {
    display: grid;
    gap: 2.2vh 1vw;
    grid-template-columns: 1fr 1fr 0.5fr 1.5fr;
    grid-template-rows: 1fr 1fr;
    grid-template-areas:
      "name name name name"
      "cpf cpf cpf birth";

    @include mobile {
      gap: 2.2vh 2vw;

      .input-box {
        .ui-input {
          font-size: clamp(0.8rem, 1.2vw, 1.2rem);

          &:focus ~ label,
          &:valid ~ label {
            font-size: clamp(0.6rem, 1vw, 1.2rem);
          }
        }

        label {
          font-size: clamp(0.8rem, 1.2vw, 1.5rem);
        }
      }
    }

    .input-box:nth-child(1) {
      grid-area: name;
    }

    .input-box:nth-child(2) {
      grid-area: cpf;
    }

    .input-box:nth-child(3) {
      grid-area: birth;
    }

    .input-box:nth-child(4) {
      grid-area: email;
    }

    .input-box:nth-child(5) {
      grid-area: password;
    }

    .input-box:nth-child(6) {
      grid-area: pass-confirm;
    }
  }

  &-address {
    margin-top: 5vh;
  }

  .policy-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 5vh;

    &-checkbox {
      margin-right: 1rem;
    }

    p {
      width: 80%;
      font-size: clamp(1rem, 1vw, 1.2rem);
      color: $text-color;
      text-align: justify;
      line-height: 1.16;
      user-select: none;

      @include mobile {
        font-size: clamp(0.8rem, 1vw, 1.2rem);
      }
    }

    &-link {
      color: $light-green;
      font-family: gilroy-bold;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &-submit-container {
    display: flex;
    justify-content: center;
    margin-top: 1rem;
  }
}
