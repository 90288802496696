@import "buttons";
@import "input";
@import "selects";
@import "hof-cards";
@import "gradient-txt";
@import "checkbox";
@import "player-room";
@import "volume";
@import "card-carousel";
@import "store-card";
@import "table2";
@import "badges";
@import "deckOrb";
@import "evoList";
@import "c-profile";
@import "c-staker-profile";
@import "c-EvoProfile";
@import "loader";
@import "line-graphic";
@import "m-input";
@import "m-form";
@import "m-faddress";

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input::-webkit-datetime-edit-day-field:focus,
input::-webkit-datetime-edit-month-field:focus,
input::-webkit-datetime-edit-year-field:focus {
  background-color: $pink;
  color: #fff;
}