@font-face {
  font-family: "gilroy";
  src: url("../../assets/font/Gilroy-Regular.ttf");
}

@font-face {
  font-family: "gilroy-medium";
  src: url("../../assets/font/Gilroy-Medium.ttf");
}

@font-face {
  font-family: "gilroy-heavy";
  src: url("../../assets/font/Gilroy-Heavy.ttf");
}

@font-face {
  font-family: "gilroy-black";
  src: url("../../assets/font/Gilroy-Black.ttf");
}

@font-face {
  font-family: "gilroy-bold";
  src: url("../../assets/font/Gilroy-Bold.ttf");
}

@font-face {
  font-family: "gilroy-extraBold";
  src: url("../../assets/font/Gilroy-ExtraBold.ttf");
}

@font-face {
  font-family: "gilroy-thin";
  src: url("../../assets/font/Gilroy-Thin.ttf");
}

@font-face {
  font-family: "digital-counter";
  src: url("../../assets/font/Digital-Counter.ttf");
}

@font-face {
  font-family: "grafite";
  src: url("../../assets/font/Grafiteg.ttf");
}

@font-face {
  font-family: "montecarlo";
  src: url("../../assets/font/MonteCarlo.ttf");
}
