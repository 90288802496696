// ------------------------------MIXINS-------------------------------------- //

@mixin gradient-text($font-s: 1rem, $font-w: bold, $font-f: gilroy-medium, $style: normal) {
    background-image: linear-gradient(to right,
            $lightblue 20%,
            $pink 80%);
    background-clip: text;
    -webkit-text-fill-color: transparent;
    letter-spacing: 1px;
    font-size: $font-s;
    font-weight: $font-w;
    font-style: $style;
    font-family: $font-f;
}

// ------------------------------CLASS-NAMES-------------------------------------- //

.infoUser__nav h2 {
    @include gradient-text($font-s: 2rem);
    white-space: nowrap;
}

.gradient-txt {
    background-image: linear-gradient(to right, $lightblue 20%, $pink 70%);
    background-clip: text;
    -webkit-text-fill-color: transparent;
    letter-spacing: 1px;
    font-family: gilroy-medium;
    font-style: italic;
    white-space: nowrap;
    padding-right: 2px;
}