.deck-orb {
  position: absolute;
  top: 6rem;
  left: 4%;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: float-animation 4s ease-in-out infinite alternate;

  &::after {
    content: "";
    position: absolute;
    top: 10px;
    bottom: 10px;
    left: 10px;
    right: 10px;
    border-radius: 50%;
    background: radial-gradient(#555, #333, #222);
    filter: blur(5px);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &-bg {
    position: absolute;
    width: 100px;
    height: 100px;
    top: 20px;
    border-radius: 50%;
    background: $azorius;
    filter: blur(6px);
    animation: rotate-bg 4s linear infinite;
  }
}

// -------------------------------------------------------- //

.deck-orb-mobile {
  position: relative;
  width: 38%;
  height: 38%;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: float-animation 4s ease-in-out infinite alternate;

  &::after {
    content: "";
    position: absolute;
    top: 5px;
    bottom: 5px;
    left: 5px;
    right: 5px;
    border-radius: 50%;
    background-color: #222;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  span {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background: $azorius;
    filter: blur(6px);
    animation: rotate-bg 4s linear infinite;
  }
}
