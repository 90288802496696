.page-tournaments {
  display: flex;
  width: 100%;
  height: 100%;
  overflow: hidden;

  &-left {
    width: 25%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    @include large-device {
      display: none;
    }

    &-list {
      width: 90%;
      height: 100%;
      padding-top: 4.5rem;
    }
  }

  &-right {
    width: 75%;
    height: 100%;

    @include large-device {
      width: 100%;
    }

    &-top {
      height: 20%;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      margin-bottom: 1.5rem;

      &_input-box {
        width: 60%;
        margin-bottom: 2rem;
      }
    }

    &-carousel {
      width: 100%;
      height: 100%;

      .main-swiper {
        height: 100%;
        padding: 1rem 0;
      }
    }
  }
}
