.loading-page {
    display: grid;
    place-items: center;
    width: 100vw;
    height: 100vh;
    position: absolute;

    .loader {
        position: absolute;
        width: clamp(8rem, 10vw, 14rem);
        height: clamp(8rem, 10vw, 14rem);
        border-radius: 50%;
        background: linear-gradient(#c502b2, #2ac1d8);
        display: flex;
        align-items: center;
        justify-content: center;

        &::after {
            content: "";
            position: absolute;
            top: 10px;
            bottom: 10px;
            right: 10px;
            left: 10px;
            background: #2a004f;
            background-size: cover;
            border-radius: 50%;
        }

        span {
            position: absolute;
            width: 100%;
            height: 100%;
            border-radius: 50%;
            background: linear-gradient(#c502b2, #2ac1d8);
            animation: load 1.5s linear infinite;

            &:nth-child(1) {
                filter: blur(5px);
            }

            &:nth-child(2) {
                filter: blur(10px);
            }

            &:nth-child(3) {
                filter: blur(25px);
            }

            &:nth-child(4) {
                filter: blur(50px);
            }
        }
    }
}