.room-page {
  // display: flex;
  // align-items: center;
  // justify-content: center;
  // height: 100%;
  // overflow-y: hidden;

  // &_match {
  //   display: flex;
  //   flex-direction: column;
  //   align-items: center;
  //   width: 100%;
  //   height: 100%;

  //   &-zero {
  //     position: absolute;
  //     display: flex;
  //     width: 70%;
  //     font-size: clamp(4rem, 5vw, 6rem);
  //     justify-content: space-evenly;
  //     top: 29%;

  //     h1 {
  //       text-align: center;
  //     }
  //   }

  //   span {
  //     position: absolute;
  //     top: 20%;
  //     margin: 1rem 0;
  //     font-size: clamp(2rem, 3vw, 3rem);
  //     font-style: italic;
  //     letter-spacing: 4px;
  //     text-transform: uppercase;
  //     color: $pink;

  //     // @include large-device {
  //     //   // top: 5;
  //     // }
  //   }

  //   .alert-confirm {
  //     position: absolute;
  //     top: 55%;
  //     z-index: 100;
  //     width: 90px;
  //     height: 90px;
  //     background-color: $lightblue;
  //     display: flex;
  //     align-items: center;
  //     text-align: center;
  //     border-radius: 50%;
  //     font-size: 0.9rem;
  //     animation-duration: 500ms;
  //     animation-delay: 2s;
  //   }

  //   &-rounds {
  //     display: flex;
  //     justify-content: space-between;
  //     width: 100%;
  //     height: 100%;
  //     margin-top: 5%;

  //     @include extra-large-device {
  //       margin-top: 0;
  //     }

  //     &-p1,
  //     &-p2 {
  //       position: absolute;
  //       display: flex;
  //       justify-content: center;
  //       width: 35rem;
  //       // background: red;

  //       @include extra-large-device {
  //         transform: scale(0.7);
  //       }
  //     }

  //     &-p1 {
  //       left: 5%;

  //       @include extra-large-device {
  //         left: -5%;
  //       }

  //       @include large-device {
  //         left: -8%;
  //       }
  //     }

  //     &-p2 {
  //       right: 5%;

  //       @include extra-large-device {
  //         right: -5%;
  //       }

  //       @include large-device {
  //         right: -8%;
  //       }
  //     }

  //     &-results {
  //       width: 100%;
  //       display: flex;
  //       justify-content: center;
  //       position: absolute;
  //       margin-top: 80px;

  //       @include extra-large-device {
  //         margin-top: 5%;
  //       }

  //       &-versus {
  //         position: absolute;
  //         top: 20%;
  //         display: flex;
  //         justify-content: center;
  //         min-width: 10rem;
  //         margin: 4rem 6rem 0 6rem;
  //         position: relative;

  //         P {
  //           font-size: clamp(7rem, 8.2vw, 10rem);
  //           font-weight: bold;
  //           line-height: 0;
  //           position: absolute;
  //           top: 3rem;
  //           text-shadow: 0 0 5px #111, 0 0 18px $pink;
  //           opacity: 0;
  //           animation: versus-title-animation 300ms linear 1s forwards;

  //           @include extra-large-device {
  //             top: 1rem;
  //           }
  //         }

  //         p:nth-child(1) {
  //           left: 1.5rem;
  //         }

  //         p:nth-child(2) {
  //           left: 4.6rem;
  //           animation-delay: 1310ms;

  //           @include extra-large-device {
  //             left: 4.2rem;
  //           }
  //         }
  //       }

  //       &-buttons {
  //         display: flex;
  //         flex-direction: column;
  //         padding: 10rem 4rem 0px 4rem;
  //       }
  //     }
  //   }
  // }

  &_chat-container {
    position: absolute;
    bottom: 2%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 30vw;
    height: 30vh;

    @include extra-large-device {
      height: 25vh;
      width: 40vw;
    }

    &::before {
      content: "";
      background: linear-gradient(45deg, #28bed5, #175f8f, #4c1474, #ff28f1, #28bed5, #175f8f, #4c1474, #ff28f1, #28bed5);
      background-size: 400%;
      position: absolute;
      top: -3px;
      left: -3px;
      z-index: -1;
      // filter: blur(5px);
      width: calc(100% + 6px);
      height: calc(100% + 6px);
      animation: glowing-rgb 20s linear infinite alternate;
      border-radius: 8px;
    }

    &::after {
      content: "";
      width: 100%;
      height: 100%;
      background: #222;
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      border-radius: 8px;
    }

    &-messages {
      background-color: rgba(0, 0, 0, 0.5);
      // backdrop-filter: blur(5px);
      border: 1px solid rgba($color: #333, $alpha: 0.5);
      border-radius: 0 8px 0 0;
      border-bottom: none;
      width: 100%;
      height: 85%;
      padding: 10px;
      letter-spacing: 1px;
      overflow-y: auto;
      overflow-x: hidden;
      transition: 250ms ease-in-out;

      %chat-box-size {
        border-radius: 8px;
        max-width: 78%;
        padding: 10px;
        margin: 5px;
        position: relative;
      }

      .chat-box-vs {
        @extend %chat-box-size;
        text-align: right;
        background: rgba(200, 0, 150, 0.1);
      }

      .chat-box-author {
        @extend %chat-box-size;
        background: rgba(0, 200, 150, 0.1);
      }
    }

    &-input {
      height: 100%;
      width: 100%;
      outline: none;
      border: 1px solid $blue;
      border-radius: 0 0 8px 8px;
      font-size: 1.2rem;
      letter-spacing: 1px;
      padding: 0 0.8rem;
      color: $pink;
      background-color: rgba($color: #000000, $alpha: 0.5);
      transition: all 200ms ease-in-out;

      &::placeholder {
        font-style: italic;
        color: $pink;
        opacity: 0.8;
      }

      &:focus {
        background: linear-gradient(to bottom, rgba($color: #000000, $alpha: 0.6) 15%, rgba($color: $blue, $alpha: 0.1) 100%);
        border-color: $lightblue;
      }
    }

    form {
      width: 100%;
      height: 15%;
    }
  }
}

// --------------------------------------- //
// MOBILE //
// --------------------------------------- //

.room-page-mobile {
  position: relative;
  width: 100vw;
  height: 100%;
  display: grid;
  grid-template-rows: 0.8fr 0.6fr 1.2fr;
  gap: 0;
  overflow-y: hidden;

  h1 {
    line-height: 1;
    font-size: 1rem;
  }

  .room-page-mobile-box1 {
    display: flex;
    width: 100%;

    &__left {
      width: 50%;
      height: 100%;
      text-align: center;
      padding-top: 2vh;

      h1 {
        font-size: 8.5vw;
        text-transform: uppercase;
        margin-bottom: 1.5vh;
      }

      button {
        font-size: 1rem;
        padding: 0.4rem !important;
      }
    }

    &__right {
      width: 50%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: flex-end;
      padding-bottom: 1rem;

      .card-box {
        width: 40vw;
        height: 40vw;
      }
    }
  }

  .room-page-mobile-box2 {
    display: flex;
    width: 100%;

    .score-btns-box {
      width: 50%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .button-round-mobile {
        width: 6vw;
        height: 6vw;
        border: 2px solid $pink;
        border-radius: 50%;
        margin-top: 2vh;
      }
    }

    &__score {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      position: relative;

      b {
        position: absolute;
        line-height: 1;
        font-size: 30vw;
        font-family: gilroy-medium;
        font-weight: normal;
      }

      b:first-child {
        left: -0.8rem;
      }

      b:last-child {
        right: -0.8rem;
      }

      &-vs {
        display: flex;
        align-items: center;
        position: relative;
        height: 100%;

        P {
          font-size: 20vw;
          font-weight: bold;
          position: absolute;
          text-shadow: 0 0 5px #111, 0 0 8px $pink;
          opacity: 0;
          animation: versus-title-animation 300ms linear 1s forwards;
        }

        p:nth-child(1) {
          left: -1.7rem;
        }

        p:nth-child(2) {
          left: -2px;
          font-size: 18vw;
          animation-delay: 1310ms;
        }
      }
    }
  }

  .room-page-mobile-box3 {
    position: relative;
    width: 100%;

    &__top {
      width: 60%;
      height: 50%;
      display: flex;
      justify-content: center;

      .card-box {
        width: 40vw;
        height: 40vw;
      }
    }

    &__bottom {
      display: flex;
      justify-content: space-between;
      width: 100%;
      padding: 0 4vw;
      position: absolute;
      bottom: 7vh;

      button {
        font-size: 1rem;
        padding: 0.4rem !important;
      }
    }
  }

  &-chat {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba($color: #000000, $alpha: 0.6);
    display: none;

    &_chat-container {
      position: absolute;
      bottom: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 90%;

      &::before {
        content: "";
        background: linear-gradient(45deg, #28bed5, #175f8f, #4c1474, #ff28f1, #28bed5, #175f8f, #4c1474, #ff28f1, #28bed5);
        background-size: 400%;
        position: absolute;
        top: -3px;
        left: -3px;
        z-index: -1;
        // filter: blur(5px);
        width: calc(100% + 6px);
        height: calc(100% + 6px);
        animation: glowing-rgb 20s linear infinite alternate;
        border-radius: 8px;
      }

      &::after {
        content: "";
        width: 100%;
        height: 100%;
        background: #222;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        border-radius: 8px;
      }

      &-messages {
        background-color: rgba(0, 0, 0, 0.5);
        // backdrop-filter: blur(5px);
        border: 1px solid rgba($color: #333, $alpha: 0.5);
        border-radius: 0 8px 0 0;
        border-bottom: none;
        width: 100%;
        height: 85%;
        padding: 10px;
        letter-spacing: 1px;
        overflow-y: auto;
        overflow-x: hidden;
        transition: 250ms ease-in-out;

        %chat-box-size {
          border-radius: 8px;
          max-width: 78%;
          padding: 10px;
          margin: 5px;
          position: relative;
        }

        .chat-box-vs {
          @extend %chat-box-size;
          text-align: right;
          background: rgba(200, 0, 150, 0.1);
        }

        .chat-box-author {
          @extend %chat-box-size;
          background: rgba(0, 200, 150, 0.1);
        }
      }

      &-input {
        // height: 100%;
        width: 100%;
        outline: none;
        border: 1px solid $blue;
        border-radius: 0 0 8px 8px;
        font-size: 1.2rem;
        letter-spacing: 1px;
        padding: 0 0.8rem;
        color: $pink;
        background-color: rgba($color: #000000, $alpha: 0.5);
        transition: all 200ms ease-in-out;

        &::placeholder {
          font-style: italic;
          color: $pink;
          opacity: 0.8;
        }

        &:focus {
          background: linear-gradient(to bottom, rgba($color: #000000, $alpha: 0.6) 15%, rgba($color: $blue, $alpha: 0.1) 100%);
          border-color: $lightblue;
        }
      }

      form {
        width: 100%;
        height: 15%;
      }
    }
  }
}
