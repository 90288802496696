.tableGrid {
  width: 90%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  font-family: gilroy;
  border-spacing: 0px;
}

.table {
  border-collapse: collapse;
  margin-right: 15px;
  margin-left: 1px;
  position: relative;

  // &-header {
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  // }
}

.nameImgTable {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  padding-top: 15px;
}

.degradeName {
  margin-top: 10px;
  font-size: 1rem;
  font-weight: bold;
  font-style: italic;
  /* letter-spacing: 0.1em; */
  background: -webkit-linear-gradient(left, #2ac1d8 10%, #ff28f1 50%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

th {
  /* position: fixed; */
  padding: 3px;
  text-align: left;
  font-size: 12px;
  font-weight: 100;
  font-family: gilroy;
  padding: 10px;
}

tr {
  height: 40px;
  cursor: pointer;
  transition: 0.125s;
}

tr:hover {
  backdrop-filter: blur(5px);
  background: rgba(42, 193, 216, 0.2) !important;
  outline: 1px solid var(--lightblue);
}

td {
  text-align: left;
  height: 40px;
  padding: 10px;
  vertical-align: middle;
}

.headerTable {
  background: rgba(0, 0, 0, 0.7);
}

@media (max-width: 770px) {
  .tableGrid {
    max-height: 50vh;
    overflow-y: auto;
  }
}