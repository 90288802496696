@keyframes color_anim {
    0% {
        fill: white;
    }

    50% {
        fill: #ff28f1;
    }

    100% {
        fill: white;
    }
}

@keyframes float-animation {
    0% {
        box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);
        transform: translatey(0px);
    }

    50% {
        box-shadow: 0 25px 15px 0px rgba(0, 0, 0, 0.2);
        transform: translatey(-20px);
    }

    100% {
        box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);
        transform: translatey(0px);
    }
}

@keyframes glowing-rgb {
    0% {
        background-position: 0 0;
    }

    50% {
        background-position: 400% 0;
    }

    100% {
        background-position: 0 0;
    }
}

@keyframes slide-animation {
    from {
        transform: translateX(-75%);
        opacity: 0;
    }

    to {
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes scale-animation {
    from {
        transform: scale(1);
    }

    to {
        transform: scale(1.1);
    }
}

@keyframes opacity-animation {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}

@keyframes versus-title-animation {
    0% {
        transform: scale(10);
        opacity: 0;
    }

    85% {
        transform: scale(.8);
        opacity: .8;
    }

    100% {
        transform: scale(1);
        opacity: 1;
    }
}

@keyframes rotate-bg {
    0% {
        transform: rotate(0);
    }

    100% {
        transform: rotate(360deg);
    }
}




@keyframes animate-line {
    0% {
        stroke-dashoffset: 200%;
    }

    100% {
        stroke-dashoffset: 0%;
    }
}

@keyframes animate-opacity {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes staked-animation {
    0% {
        box-shadow: 0 0 $lightblue;
    }

    100% {
        box-shadow: 0 5px 20px 2px $lightblue;
    }
}

@keyframes load {
    to {
        transform: rotate(0deg);
    }

    from {
        transform: rotate(360deg);
    }
}

@keyframes color-icon {
    to {
        color: $lightblue;

    }

    from {
        color: $pink;
    }
}

@keyframes shadow-icon {
    to {
        filter: drop-shadow(0 0 1px currentColor);
    }

    from {
        filter: drop-shadow(0 0 5px currentColor);
    }
}