.listGrid {
  display: flex;
  align-items: flex-start;
  position: relative;
  width: 100%;
  height: 100%;

  .listDiv {
    position: relative;
    height: 87%;
    min-height: 70vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    @include mobile {
      padding-top: 5rem;
    }

    .tourments-button-box {
      position: relative;
      height: 10%;
      padding-left: 12vw;
      margin-top: 1rem;

      .list-button {
        font-size: 0.9rem;
        padding: 0.5rem !important;
      }

      @include mobile {
        position: absolute;
        padding: 0;
        right: 50%;
        top: 0;
        transform: translateX(50%);
        z-index: 100;
      }
    }

    .listUL {
      display: flex;
      flex-direction: column-reverse;
      justify-content: flex-start;
      width: 100%;
      min-height: 100%;
      padding: 0 2rem;
      position: relative;
      overflow-y: auto;

      .linhaList {
        position: fixed;
        z-index: -1;
        background: radial-gradient(#4c1474 60%, transparent 95%);
        height: 70%;
        width: 4px;
      }

      @include large-device {
        &::-webkit-scrollbar {
          display: none;
        }
      }

      @include mobile {
        padding: 0 1rem;
      }

      p {
        font-size: clamp(1.2rem, 1.5vw, 1.8rem);
        text-decoration: 2px underline $lightblue;
        position: relative;

        &::before {
          content: "";
          position: absolute;
          left: -4rem;
          width: 3px;
          height: 60vh;
          background: radial-gradient(#4c1474 40%, transparent 80%);
          transform: translateY(-50%);
        }
      }

      .list-item {
        width: 85%;
        display: flex;
        max-height: 120px;
        flex-direction: column;
        justify-content: center;
        border-radius: 22px;
        margin-bottom: 0.2rem;
        margin-left: 2rem;
        padding: 1.2rem;
        user-select: none;
        transition: $cubic-main;

        @include large-device {
          width: 90%;
        }

        @include mobile {
          width: 95%;
          margin-left: 1rem;
        }

        @include extra-small-mobile {
          min-height: 5.7rem;
        }

        &:hover {
          background: rgba(0, 0, 0, 0.2);
          backdrop-filter: blur(5px);
        }

        &__link {
          position: relative;

          &__header {
            display: flex;
            align-items: flex-end;
            font-size: clamp(1rem, 1.2vw, 1.4rem);

            @include extra-small-mobile {
              font-size: clamp(0.8rem, 1.2vw, 1.4rem);
            }

            span {
              color: $pink2;
              margin-right: 10px;
              white-space: nowrap;
            }
          }

          &__title {
            display: flex;
            align-items: center;
            font-size: clamp(1.3rem, 1.5vw, 2rem);
            font-family: gilroy-medium;
            font-weight: bold;
            letter-spacing: 2px;
            margin: 4px 0px;
            white-space: nowrap;

            @include mobile {
              margin: 0;
            }

            @include extra-small-mobile {
              font-size: clamp(1rem, 1.5vw, 2rem);
            }

            .dot {
              background: $purple;
              border-radius: 50%;
              width: 1.5rem;
              height: 1.5rem;
              position: absolute;
              left: -3.7rem;
              z-index: 10000;

              @include mobile {
                width: 1rem;
                height: 1rem;
                left: -2.25rem;
              }

              // &::before {
              //   content: "";
              //   position: absolute;
              //   z-index: -1;
              //   top: -15rem;
              //   left: 50%;
              //   transform: translateX(-50%);
              //   background: radial-gradient(#4c1474 60%, transparent 95%);
              //   height: 30rem;
              //   width: 4px;
              // }
            }
          }

          &__footer {
            display: flex;
            width: 100%;
            font-size: clamp(1rem, 1.125vw, 1.4rem);

            @include extra-small-mobile {
              font-size: clamp(0.8rem, 1.125vw, 1.4rem);
            }

            &__sub {
              color: $lightblue;
              font-style: italic;
              display: flex;
              align-items: center;
              white-space: nowrap;
            }

            &__trophy {
              color: $yellow;
              display: flex;
              align-items: center;
              white-space: nowrap;

              .trophyIcon {
                background-image: url("../../assets/img/trophyIcon.webp");
                background-size: contain;
                background-position: center;
                background-repeat: no-repeat;
                width: 1.3rem;
                height: 1.8rem;
                margin-right: 0.25rem;

                @include extra-small-mobile {
                  width: 0.8rem;
                  height: 1.3rem;
                }
              }
            }

            &__users {
              color: $pink2;
              display: flex;
              align-items: center;
              font-style: normal;
            }

            span {
              margin-right: 5vw;

              i {
                margin-right: 5px;
              }
            }

            span:nth-child(1) i {
              color: $lightblue;
            }

            span:nth-child(3) i {
              color: $pink2;
            }
          }
        }
      }
    }
  }
}
