// ---------------------------------- //
// -- colors -- //
// ---------------------------------- //

$main-color: #fff;
$main-transition: 125ms ease-in-out;
$light-green: #05be68;
$dark-green: #043d39;
// $text-color: #333;
$error-color: #e74c3c;

// ---------------------------------- //

$lightblue: #2ac1d8;
$blue: #0b3986;
$pink: #ff28f1;
$pink2: #c502b2;
$purple: #4b007c;
$yellow: #ffe600;
$text-color: #fff;
$bg-color: radial-gradient(52% 52% at 67% 48.55%, #2a004f 8.6%, #180a29 79.39%);
$bg-scroll: linear-gradient($purple 20%, $blue 95%);
$bg-button-profiles: linear-gradient(4deg, rgba(76, 20, 116, 1) 24%, rgba(23, 95, 143, 1) 62%, rgba(42, 193, 216, 1) 95%);

$insta: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285aeb 90%);
// colors orb-deck //

$boros: linear-gradient(90deg, #fffbd5 0%, #b20a2c 100%);
$selesnya: red;
$azorius: linear-gradient(90deg, #005aa7 0%, #fffde4 100%);
$orzhov: linear-gradient(90deg, #bdc3c7 0%, #2c3e50 90%);
$gruul: red;
$izzet: linear-gradient(90deg, #009fff 0%, #ec2f4b 100%);
$rakdos: linear-gradient(90deg, #8e0e00 0%, #1f1c18 100%);
$simic: red;
$golgari: linear-gradient(90deg, #0f3443 20%, #34e89e 100%);
$dimir: red;

// $mono-red: linear-gradient(90deg, #FF416C 0%, #FF4B2B 100%);
$mono-red: linear-gradient(90deg, #fc4a1a 20%, #f7b733 100%);
$mono-blue: linear-gradient(90deg, #0575e6 20%, #021b79 100%);
$mono-green: linear-gradient(90deg, #093028 20%, #237a57 100%);
$mono-black: black;
$mono-white: white;
$all-colors: linear-gradient(90deg, #5433ff 0%, #f953c6 25%, #20bdff 50%, #ff0099 75%, #a5fecb 100%);

// ---------------------------------- //
// -- transitions -- //
// ---------------------------------- //

$cubic-bezier-buttons: cubic-bezier(1, 0, 0.66, 0.99);
$cubic-main: 200ms ease-in-out;

// ---------------------------------- //

// :root {
//   --lightblue: #2ac1d8;
//   --pink: #ff28f1;
//   --pink2: #c502b2;
//   --blue: #5291da;
//   --purple: #4c1474;
//   --yellow: #ffe600;
//   --gray: rgba(0, 0, 0, 0.2);

//   // colors orb-deck //

//   // Four Colors
//   --yoretiller: linear-gradient(90deg, white 0%, blue 30%, black 60%, red 100%);
//   --glinteye: linear-gradient(90deg, blue 0%, black 30%, green 60%, red 100%);
//   --dunebrood: linear-gradient(90deg, white 0%, green 30%, black 60%, red 100%);
//   --inktreader: linear-gradient(90deg,
//       white 0%,
//       blue 30%,
//       black 60%,
//       green 100%);
//   --witchmaw: linear-gradient(90deg, white 0%, blue 30%, black 60%, green 100%);

//   // three colors
//   --bant: linear-gradient(90deg, white 0%, green 50%, blue 100%);
//   --esper: linear-gradient(90deg, blue 0%, white 50%, black 100%);
//   --grixis: linear-gradient(90deg, blue 0%, black 50%, red 100%);
//   --jund: linear-gradient(90deg, red 0%, green 50%, blue 100%);
//   --naya: linear-gradient(90deg, red 0%, green 50%, white 100%);
//   --abzan: linear-gradient(90deg, white 0%, black 50%, green 100%);
//   --jeskai: linear-gradient(90deg, blue 0%, black 50%, green 100%);
//   --sultai: linear-gradient(90deg, black 0%, green 50%, blue 100%);
//   --mardu: linear-gradient(90deg, white 0%, red 50%, black 100%);
//   --temur: linear-gradient(90deg, blue 0%, green 50%, red 100%);

//   // Two Colors
//   --boros: linear-gradient(90deg, #fffbd5 0%, #b20a2c 100%);
//   --selesnya: linear-gradient(90deg, white 0%, green 100%);
//   --azorius: linear-gradient(90deg, #005aa7 0%, #fffde4 100%);
//   --orzhov: linear-gradient(90deg, #bdc3c7 0%, #2c3e50 90%);
//   --gruul: linear-gradient(90deg, red 0%, green 100%);
//   --izzet: linear-gradient(90deg, #009fff 0%, #ec2f4b 100%);
//   --rakdos: linear-gradient(90deg, #8e0e00 0%, #1f1c18 100%);
//   --simic: linear-gradient(90deg, green 0%, blue 100%);
//   --golgari: linear-gradient(90deg, #0f3443 20%, #34e89e 100%);
//   --dimir: linear-gradient(90deg, blue 0%, white 100%);

//   // One Color
//   // $mono-red: linear-gradient(90deg, #FF416C 0%, #FF4B2B 100%);
//   --mono-red: linear-gradient(90deg, #fc4a1a 20%, #f7b733 100%);
//   --mono-blue: linear-gradient(90deg, #0575e6 20%, #021b79 100%);
//   --mono-green: linear-gradient(90deg, #093028 20%, #237a57 100%);
//   --mono-black: linear-gradient(90deg, black 0%, purple 100%);
//   --mono-white: white;
//   --all-colors: linear-gradient(90deg,
//       #5433ff 0%,
//       #f953c6 25%,
//       #20bdff 50%,
//       #ff0099 75%,
//       #a5fecb 100%);
// }