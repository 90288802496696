.PLAYERgraphicGrid {
    width: 100%;
    z-index: 1;
}

.PLAYERgraphicBlock {
    width: 100%;
    height: 20vh;
}

.PLAYERtextBTN {
    font-size: 0.6em;
    width: 60%;
    font-weight: 100;
    z-index: 1;
}

.PLAYERgraphicFooter {
    display: flex;
    height: 50px;
    width: 100%;
}

.PLAYERvaloresGraphic {
    display: block;
    margin: auto;
    height: 50px;
    margin-top: -30px;
    font-size: 13px;
}

.PLAYERtraco {
    width: 20px;
    margin-right: 10px;
}

.PLAYERlinemark-series-example-2 {
    width: 100%;
}

/* css pizza graphic */

.pizzaGraphicGrid {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.pizzaGraphicBlock {
    margin: 10px;
}

.pizzaGraphicBlock canvas {
    /* margin: 30px;
  padding: 10px; */
    /* width: 400px; */
    margin-left: 50px;
}
