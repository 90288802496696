.rankingPage {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  font-family: gilroy;
}

.containerRankingLeft {
  width: 40%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 45px 20px 20px 20px;
}

.rankingBanner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 90%;
  height: 40%;
  /* background-color: rgb(161, 4, 4); */
}

.rankingBanner .banner {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.8rem;
  width: 100%;
  height: 10.5rem;
  min-width: 225px;
  background-image: url(../../assets/img/bg6.webp);
  background-position: center;
  background-size: cover;
  user-select: none;
  box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.2);
}

.rankingBanner .banner h1 {
  font-size: 3rem;
  font-weight: bold;
  font-family: Gilroy-medium;
  letter-spacing: 1px;
  /* text-shadow: 1px 1px 2px #111; */
}

.rankingBanner .descriptionBanner {
  font-size: 1.5rem;
  font-family: Gilroy-Thin;
  text-align: start;
  letter-spacing: 1px;
  margin-top: 1.4rem;
  width: 100%;
}

.rankingBanner .descriptionBanner::selection {
  background: var(--pink);
  color: #fff;
}

.rankingProfile {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 50%;
}

.rankingProfileContent {
  width: 85%;
  height: 100%;
}

.containerRankingRight {
  width: 60%;
  height: 100%;
  display: flex;
  align-items: flex-end;
  /* background-color: teal; */
}

.tableRanking {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 90%;
}

@media (max-width: 768px) {
  .rankingPage {
    display: block;
  }
  .containerRankingLeft {
    display: flex;
    width: 100vw;
    height: 40%;
  }
  .rankingBanner {
    width: 50%;
    height: 100%;
  }
  /* .rankingBanner .banner {

    } */
  .rankingProfile {
    width: 50%;
    height: 100%;
  }
  .containerRankingRight {
    width: 100vw;
    height: 60%;
  }
}

@media (max-width: 625px) {
  .containerRankingLeft {
    display: flex;
    flex-direction: column;
    height: 50%;
    width: 100vw;
  }
  .containerRankingRight {
    height: 40%;
    width: 100%;
  }
  .rankingBanner {
    width: 100vw;
    height: 50%;
  }
  .rankingProfile {
    width: 100vw;
    height: 50%;
  }
  .rankingBanner p {
    overflow-y: scroll;
  }
}
