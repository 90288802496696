@import '../../index.scss';

.stakes-modal {
  background: rgba($color: #000000, $alpha: 0.7);
  backdrop-filter: blur(15px);
  padding: 1.2rem;
  text-align: center;
  border: 1px solid;
  border-color: rgba($color: $lightblue, $alpha: 1);
  border-radius: 6px;

  h3 {
    margin-bottom: 1.5rem;
  }

  &-title {
    margin-bottom: 1rem;

    span {
      font-size: 1.2rem;
      letter-spacing: 0.5px;

      &:nth-child(2) {
        color: $yellow;
        font-weight: bold;
      }
    }
  }

  input {
    padding: 0.8rem 1rem;
    background-color: transparent;
    font-size: 1.2rem;
    color: $pink;
    letter-spacing: 1px;
    outline: none;
    border: 1px solid $purple;
    border-radius: 8px;
    transition: 0.3s;

    &::placeholder {
      opacity: 0.5;
    }

    &:focus {
      border-color: $pink;
      background: rgba($color: $purple, $alpha: 0.2);
    }
  }

  &-buttons {
    margin-top: 20px;
    display: flex;
    justify-content: center;

    button {
      width: 50%;
    }
  }

  button {
    background: $pink;
    border: none;
    border-radius: 8px;
    padding: 0.9rem;
    cursor: pointer;
    margin-left: 10px;
    font-size: 1rem;
    font-family: gilroy-medium;
    font-weight: bold;
    letter-spacing: 0.5px;
    transition: 0.2s;
    position: relative;
    overflow: hidden;
    color: #ededed;

    &:hover {
      transform: scale(1.07);
      color: #fff;
    }

    &:hover::after {
      transform: scale(1);
      //   transform-origin: left;
    }

    &::after {
      content: "";
      width: 100%;
      height: 100%;
      background: rgba($color: #000000, $alpha: 0.5);
      position: absolute;
      z-index: -1;
      top: 0;
      left: 0;
      transform: scale(0);
      //   transform-origin: right;
      transition: transform 125ms ease-in;
    }
  }
}

.footer-buttons {
  display: flex;
  align-items: center;
  justify-content: center;

  button {
    margin: 2rem;
    padding: 1rem 2rem;
    width: 25%;
    border: 1px solid $pink;
    border-radius: 8px;
    background: rgba($color: #000000, $alpha: 0.4);
    // backdrop-filter: blur(5px);
    font-size: 1.2rem;
    font-weight: bold;
    letter-spacing: 1px;
    text-transform: uppercase;
    cursor: pointer;
    color: $pink;
    transition: 0.2s;

    &:hover {
      background: $pink;
      color: #fff;
    }
  }
}