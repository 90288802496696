@mixin reset-css {
  color: #fff;
  font-family: gilroy;
  text-decoration: none;
  z-index: 0;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  vertical-align: baseline;
  list-style: none;
  border-collapse: collapse;
  user-select: none;

  input {
    user-select: text;
  }

  canvas {
    z-index: -1 !important;
  }

  &::placeholder {
    color: rgba($color: $pink, $alpha: 0.6);
    letter-spacing: 1px;
  }

  &::selection {
    background-color: $pink;
    color: #fff;
    -webkit-text-fill-color: #fff;
  }

  &::-webkit-scrollbar {
    width: 0.9rem;
    height: 1rem;
  }

  &::-webkit-scrollbar-track {
    background-color: #000;
    border-radius: 100vw;
    margin-block: 0.5rem;
    visibility: hidden;
  }

  &::-webkit-scrollbar-thumb {
    background: $bg-scroll;
    border-radius: 100vw;
    border: 3px solid black;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: $bg-scroll;
    filter: saturate(110%);
  }

  @supports (scrollbar-color: linear-gradient(#4b007c 20%, #0b3986 95%) #0b3986 #000000) {
    * {
      scrollbar-color: linear-gradient(#4b007c 20%, #0b3986 95%) #000000;
      scrollbar-width: thin;
    }
  }
}
