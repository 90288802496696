// ----------------------------------- //
// new add deck page //
// ----------------------------------- //

.page-addDeck {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: 68% 32%;
  min-height: 500px;

  &-slider_container {
    display: grid;
    grid-template-rows: 15% 85%;

    &-search-container {
      display: flex;
      align-items: center;
      justify-content: center;

      form {
        display: flex;
        align-items: center;
        justify-content: center;

        .search-div {
          width: 17vw;
          min-width: 170px;
          margin-right: 0.6rem;
        }

        select {
          width: 10vw;
          min-width: 120px;
        }
      }

      button {
        margin-left: 0.6rem;
      }
    }

    &-swiper {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
    }
  }

  &-deck_list {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;

    &-deck-div {
      display: flex;
      width: 50%;
      height: 100%;
      margin-left: auto;

      .mana {
        min-width: 15px;
        width: 1vw;
        height: 1vw;
        min-height: 15px;
        border-radius: 50%;
      }

      .wasteMana {
        display: grid;
        place-items: center;
        min-width: 15px;
        width: 1vw;
        height: 1vw;
        min-height: 15px;
        border-radius: 50%;
        background: rgb(90, 90, 90);
      }

      .deck-component {
        height: 100%;
        width: 55%;
        margin-right: 1vw;

        &-title {
          display: flex;
          height: 15%;
          width: 100%;

          h1 {
            align-self: flex-start;
            font-size: clamp(1.2rem, 2vw, 2rem);
            text-transform: uppercase;
            letter-spacing: 1px;
          }

          p {
            margin-left: auto;
            margin-right: 1vw;
            align-self: flex-end;
            white-space: nowrap;
          }
        }

        &-content {
          display: flex;
          height: 85%;
          width: 100%;

          &-table {
            height: 100%;
            width: 100%;
            overflow: auto;
            overflow-x: hidden;

            table {
              height: 95%;
              margin: 2px;

              tr {
                display: inline-flex;
                width: 100%;

                td {
                  font-size: clamp(0.6rem, 0.84vw, 1rem);
                  white-space: nowrap;
                  text-overflow: ellipsis;
                }

                td:last-child {
                  display: flex;
                  margin-left: auto;
                }
              }
            }
          }
        }
      }

      .sideBoard-component {
        width: 45%;

        &-title {
          display: flex;
          height: 15%;
          width: 100%;

          h1 {
            align-self: flex-start;
            font-size: clamp(1.2rem, 2vw, 2rem);
            text-transform: uppercase;
            letter-spacing: 1px;
          }

          p {
            margin-left: auto;
            margin-right: 1vw;
            align-self: flex-end;
            white-space: nowrap;
          }
        }

        &-content {
          height: 85%;
          width: 100%;

          &-table {
            height: 100%;
            width: 100%;
            overflow-y: auto;
            overflow-x: hidden;

            table {
              height: 95%;
              margin: 2px;

              tr {
                display: inline-flex;
                width: 100%;

                td {
                  font-size: clamp(0.6rem, 0.84vw, 1rem);
                  white-space: nowrap;
                  text-overflow: ellipsis;
                }

                td:last-child {
                  display: flex;
                  margin-left: auto;
                }
              }
            }
          }
        }
      }
    }

    &-name-deck {
      display: flex;
      align-self: flex-start;
      margin: 0 1.5vw;
      margin-top: 0.6rem;

      input {
        width: 17vw;
        min-width: 170px;
        margin-right: 0.6rem;
      }
    }
  }

  .deck-img-preview {
    position: absolute;
    bottom: 1rem;
    left: 7%;
    width: 12vw;
    aspect-ratio: 3/4;
    border-radius: 5%;
  }
}

// ----------------------------------- //
// SLIDER CARDS DECK //
// ----------------------------------- //

.swiper-cards {
  width: 90vw;

  &-item {
    width: 15vw;
    aspect-ratio: 3/4;
    // margin: 0.6rem 0;

    img {
      height: 100%;
      width: 100%;
      border-radius: 17px;
    }
  }
}

// ----------------------------------- //
