.room-page {
  width: 100%;
  height: 100%;
  min-height: 500px;
  overflow-y: auto;
  display: grid;
  grid-template-rows: 20% 50% 30%;

  &-container1 {
    display: flex;
    justify-content: center;
    width: 100%;

    &-middle {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      text-align: center;
      height: 100%;

      span {
        font-size: clamp(2rem, 3vw, 3.8rem);
        font-style: italic;
        letter-spacing: 4px;
        text-transform: uppercase;
        color: $pink;
      }
    }
  }

  &-container2 {
    display: flex;
    justify-content: center;
    width: 100%;
    // background: rgba($color: #000000, $alpha: .2);

    h1 {
      display: grid;
      place-items: center;
      height: 50%;
      // width: 15%;
      font-size: clamp(7rem, 8.2vw, 10rem);
      // background: #111;
    }

    &-playerBox {
      display: grid;
      place-items: center;
      height: 100%;
      width: 50%;
    }

    &-middle {
      width: 15vw;
      height: 100%;

      @include extra-large-device {
        min-width: 150px;
      }

      &-vs {
        position: relative;
        height: 50%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        p {
          position: absolute;
          font-size: clamp(7rem, 8.2vw, 10rem);
          font-weight: bold;
          text-shadow: 0 0 5px #111, 0 0 18px $pink;
        }

        p:nth-child(1) {
          margin-left: -20%;
        }

        p:nth-child(2) {
          margin-right: -20%;
          animation-delay: 1310ms;
        }
      }

      &-marks {
        display: flex;
        height: 40%;
        width: 100%;

        .markBox {
          display: grid;
          place-items: center;
          width: 50%;
          height: 100%;
        }
      }
    }
  }

  &-container3 {
    width: 100%;
    display: flex;
    justify-content: center;

    &-drop {
      margin: auto;
      visibility: hidden;
    }

    &-chat {
      position: relative;
      display: inline-flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 95%;
      width: 40vw;
      margin: auto 0 3px auto;

      @include extra-large-device {
        min-width: 350px;
      }

      &::before {
        content: "";
        background: linear-gradient(45deg, #28bed5, #175f8f, #4c1474, #ff28f1, #28bed5, #175f8f, #4c1474, #ff28f1, #28bed5);
        background-size: 400%;
        position: absolute;
        top: -3px;
        left: -3px;
        z-index: -1;
        // filter: blur(5px);
        width: calc(100% + 6px);
        height: calc(100% + 6px);
        animation: glowing-rgb 20s linear infinite alternate;
        border-radius: 8px;
      }

      &::after {
        content: "";
        width: 100%;
        height: 100%;
        background: #222;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        border-radius: 8px;
      }

      &-tabs {
        position: absolute;
        left: 0;
        top: -2.2rem;
        display: flex;
        align-items: flex-end;

        &-button {
          // width: 150px;
          padding: 0 0.6rem;
          font-family: gilroy-bold;
          font-size: clamp(0.8rem, 1vw, 1.6rem);
          text-align: center;
          border: none;
        }
      }

      &-messages {
        background-color: rgba(0, 0, 0, 0.5);
        // backdrop-filter: blur(5px);
        border: 1px solid rgba($color: #333, $alpha: 0.5);
        border-radius: 0 8px 0 0;
        border-bottom: none;
        width: 100%;
        height: 85%;
        padding: 10px;
        letter-spacing: 1px;
        overflow-y: auto;
        overflow-x: hidden;
        transition: 250ms ease-in-out;

        %chat-box-size {
          border-radius: 8px;
          max-width: 78%;
          padding: 10px;
          margin: 5px;
          position: relative;
        }

        .chat-box-vs {
          @extend %chat-box-size;
          text-align: right;
          background: rgba(200, 0, 150, 0.1);
        }

        .chat-box-author {
          @extend %chat-box-size;
          background: rgba(0, 200, 150, 0.1);
        }
      }

      &-input {
        height: 100%;
        width: 100%;
        outline: none;
        border: 1px solid $blue;
        border-radius: 0 0 8px 8px;
        font-size: 1.2rem;
        letter-spacing: 1px;
        padding: 0 0.8rem;
        color: $pink;
        background-color: rgba($color: #000000, $alpha: 0.5);
        transition: all 200ms ease-in-out;

        &::placeholder {
          font-style: italic;
          color: $pink;
          opacity: 0.8;
        }

        &:focus {
          background: linear-gradient(to bottom, rgba($color: #000000, $alpha: 0.6) 15%, rgba($color: $blue, $alpha: 0.1) 100%);
          border-color: $lightblue;
        }
      }

      form {
        width: 100%;
        height: 15%;
      }
    }

    &-standings {
      margin: auto;
      // margin-top: auto;
      // margin-bottom: 1rem;
    }
  }
}
