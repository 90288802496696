%avatar-img-table {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 0.8rem;
  border: 4px solid $purple;
}

.container-table-staker {
  width: 100%;
  height: 100%;

  .search-box {
    margin-bottom: 1.5rem;
    display: inline-flex;
    transition: 800ms $cubic-bezier-buttons;

    input {
      width: 3.4rem;

      &::placeholder {
        opacity: 0;
      }

      &:focus {
        &::placeholder {
          opacity: 1;
        }
      }

      &:valid,
      &:focus {
        width: 45vw;
      }
    }
  }

  .table-staker-scroll {
    max-height: 100%;
    overflow-y: auto;
    overflow-x: none;
    padding: 0 20px 0 1px;

    .table-staker {
      width: 100%;

      &-header {
        background-color: rgba($color: #000000, $alpha: 0.6);
        position: sticky;
        top: 0;
        left: 0;
        z-index: 2000;
        font-size: 1.1rem;
        letter-spacing: 1px;
      }

      &-items {
        height: 5.5rem;
        cursor: pointer;
        transition: 200ms ease-in-out;
        font-size: 1.6rem;

        &-avatar {
          font-size: 1.5rem;
          letter-spacing: 1px;

          &-flex {
            font-family: gilroy-thin;
            font-weight: bold;
            display: flex;
            align-items: center;
            height: 100%;

            .table-staker-default-img {
              @extend %avatar-img-table;
            }

            img {
              @extend %avatar-img-table;
            }
          }
        }

        &-nickname {
          font-size: 1.8rem;

          b {
            padding: 0 2px;
          }
        }

        &-staked {
          color: $lightblue;
        }

        &-rewarded {
          color: $yellow;
        }
      }
    }
  }
}
