// badges-swiper //

.swiper-badge {
  margin: 10px auto;
  max-width: 70%;

  @include medium-device {
    max-width: 80%;
  }

  &-item {
    width: 5vw;
    aspect-ratio: 3/4;

    @include large-device {
      // height: 9rem;
      margin: 1rem 0;
      // width: 6.2rem;
    }

    .badge-box {
      background: url("../../assets/img/bg12.webp") no-repeat;
      background-size: cover;
      background-position: center;
      height: 100%;
      width: 100%;
      border-radius: 12px;
      text-align: center;
      letter-spacing: 1px;
      overflow: hidden;

      &-name {
        font-size: clamp(0.8rem, 0.85vw, 1rem);
        height: 30%;
        width: 100%;
        padding: 2px 3px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-transform: uppercase;
        line-height: 1;
        background-color: rgba($color: #000000, $alpha: 0.5);
      }

      &-position {
        height: 53%;
        display: grid;
        place-items: center;

        p {
          font-size: clamp(1.6rem, 1.6vw, 2.2rem);
          font-family: gilroy-medium;
          font-weight: bold;
          text-shadow: 0 0 3px black;
          filter: drop-shadow(0 0 5px $pink);
        }
      }

      &-reward {
        height: 20%;
        padding: 0 3px;
        display: grid;
        place-items: center;
      }
    }
  }
}

// ----------------------------------------- //
// NEW ACCOUNTS //
// ----------------------------------------- //

.badges-new-account-banner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 25vh;
  width: 100%;

  i {
    font-size: 1.6rem;
    margin-bottom: 0.5rem;
    color: $lightblue;

    animation: float-animation 4s ease-in-out infinite alternate, shadow-icon 2s ease infinite alternate, color-icon 5s $cubic-bezier-buttons infinite alternate;
  }

  p {
    font-size: 1.4rem;
    font-family: gilroy-bold;
    text-align: center;
    width: 80%;
  }
}
