.faddress {
  width: 100%;
  display: grid;
  grid-template-columns: 2fr 1.7fr 0.9fr 1.1fr;
  grid-template-rows: 1fr 1fr 1fr;
  gap: 2.2vh 1vw;
  grid-template-areas:
    "street street street number"
    "complement complement cep cep"
    "district city estate country";

  @include mobile {
    gap: 2.2vh 2vw;

    .input-box {
      .ui-input {
        font-size: clamp(0.8rem, 1.2vw, 1.2rem);

        &:focus ~ label,
        &:valid ~ label {
          font-size: clamp(0.6rem, 1vw, 1.2rem);
        }
      }

      label {
        font-size: clamp(0.8rem, 1.2vw, 1.5rem);
      }
    }
  }

  .input-box:nth-child(1) {
    grid-area: street;
  }

  .input-box:nth-child(2) {
    grid-area: number;
  }

  .input-box:nth-child(3) {
    grid-area: complement;
  }

  .input-box:nth-child(4) {
    grid-area: cep;
  }

  .input-box:nth-child(5) {
    grid-area: district;
  }

  .input-box:nth-child(6) {
    grid-area: city;
  }

  .input-box:nth-child(7) {
    grid-area: estate;
  }

  .input-box:nth-child(8) {
    grid-area: country;
  }
}
