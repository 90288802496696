@import 'variables';
@import 'fonts';
@import 'reset';
@import 'responsively';

* {
    @include reset-css;
}

html {
    background: $bg-color;
    height: 100vh;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    overflow: hidden;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    scroll-behavior: smooth;
}