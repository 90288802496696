.hof-page {
  display: flex;
  width: 100%;
  height: 100%;
  overflow: hidden;

  .svg-mask {
    width: 0;
    height: 0;
    position: absolute;
  }

  &_left {
    width: 45%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    user-select: none;
    padding-top: 3rem;

    .reverse {
      flex-direction: row-reverse;
    }

    &-card {
      &_infos {
        margin: 0rem 3rem;

        &_nick {
          display: flex;
          align-items: center;
          margin-bottom: 0.5rem;

          h1 {
            font-size: 2rem;
            font-style: italic;
            font-weight: bold;
            font-family: gilroy-medium;
            letter-spacing: 5px;
            margin-right: 10px;
            background: -webkit-linear-gradient(left, #2ac1d8 25%, #ff28f1 75%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }

          img {
            width: 30px;
            object-fit: contain;
            clip-path: none;
            position: relative;
          }
        }

        span {
          font-size: 1.5rem;
          font-family: gilroy-thin;

          b {
            font-family: gilroy;
          }
        }
      }
    }
  }

  &_right {
    width: 65%;
    height: 100%;

    &-top {
      height: 20%;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      font-size: 2rem;
      margin-bottom: 1.5rem;

      .hof-input-box {
        width: 37.5rem;
        margin-left: 1rem;
        margin-bottom: 2rem;
      }
    }
  }
}
