.t-adm-page {
  display: flex;
  height: 100%;
  flex-wrap: wrap;
  transition: all 0.4s ease;
  margin-top: 10px;

  .flex1 {
    flex: 1 0 400px;
  }

  .flex2 {
    flex: 2;

    .console-body {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }

  .eventControls {
    flex: 1 0 400px;
    height: 400px;

    .btn-controls {
      display: flex;
      align-items: center;

      .btn-separate {
        width: 3px;
        height: 140px;
        background-color: rgba(203, 190, 206, 0.1);
      }
    }

    .active-control {
      display: flex;
      margin-top: 10px;
      height: 190px;
      width: 100%;
      justify-content: space-between;

      div {
        flex: 1;
        margin: 5px;
        padding: 10px;
        background-color: rgba(238, 238, 238, 0.02);
        border-radius: 8px;
      }
    }
  }

  button {
    flex: 1;
    width: 200px;
    height: 150px;
    background-color: transparent;
    border: transparent;
    border-radius: 8px;
    color: rgb(167, 93, 192);
    font-size: 0.8rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .btn-controls-icon {
      display: grid;
      place-items: center;
      height: 60px;
      width: 60px;
      color: rgb(195, 158, 208);
      background-color: rgba(169, 131, 179, 0.5);
      border-radius: 50%;
    }
  }

  button:hover {
    color: rgb(228, 213, 237);

    .btn-controls-icon {
      color: rgb(212, 197, 218);
      background-color: rgba(205, 170, 214, 0.5);
    }
  }
}

.t-adm-console {
  min-width: 200px;
  height: 400px;
  margin: 5px;
  border-radius: 8px;
  background-color: rgba(238, 238, 238, 0.06);
  backdrop-filter: blur(15px);

  p {
    font-size: 0.7rem;
    color: grey;
    letter-spacing: 0.7px;
    line-height: 0.7rem;
  }

  .console-description {
    padding: 15px;
    height: 50px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
}
