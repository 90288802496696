.container-line-graphic {
    // position: relative;
    width: 100%;
    height: 100%;
    z-index: 1;
    animation: animate-opacity 2s linear forwards;

    .lineMarks {
        stroke-width: 5;
        fill: transparent;
    }

    &-subtitle {
        display: flex;
        align-items: center;
        position: absolute;
        bottom: .85rem;
        right: 2.5vw;
        font-size: 1.1rem;
        font-weight: bold;

        .subtitle-line {
            width: 1.5rem;
            height: .3rem;
            border-radius: 2px;
            background: #2ac1d8;
            margin-left: .5rem;
        }
    }
}

// ------------------------------ //

.traco {
    width: 20px;
    margin-right: 10px;
}

.linemark-series-example-2 {
    width: 80%;
}

.animateactive {
    animation: animate-line 1.3s linear forwards;
    stroke-dasharray: 240%;
}

.rv-xy-plot__series--linemark {
    filter: drop-shadow(0px 0px 7px rgba(42, 193, 216, 0.5));
}