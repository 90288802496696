@import "../../index.scss";

.cardtest {
  width: 150px;
  opacity: 0;
}

.cardtest:hover {
  width: 300px;
  opacity: 1;
}

.btnInport {
  @include default-button($scale: 0.8);
  margin: 10px;
}

.inputNickname {
  @include default-input($w: 50%);
  margin: 15px;
}
