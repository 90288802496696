.modal-add-tournament {
  width: 100%;
  height: 100%;
  backdrop-filter: blur(18px);
  text-align: center;

  h1 {
    text-transform: uppercase;
    font-size: clamp(1.6rem, 2vw, 2.7rem);
    font-family: gilroy-medium;
    letter-spacing: 2px;
  }

  &-form {
    display: flex;
    flex-direction: column;
    // width: 31vw;
    height: 80vh;
    padding: 1.2vw;
    margin-top: 1rem;
    background-color: rgba(0, 0, 0, 0.15);
    backdrop-filter: blur(15px);
    border-radius: 24px;

    @include mobile {
      padding: 5vw;
    }

    .form-hr {
      width: 100%;
      height: 1px;
      background: radial-gradient($lightblue 5%, transparent 70%);
      margin: 1rem 0;
    }

    .inputTornament {
      margin-bottom: 1rem;
      width: 100% !important;
      border-color: $lightblue !important;
      border-width: 2px !important;
      transition: 200ms ease-in-out;
      resize: none;

      &:focus {
        background-color: rgba($color: $lightblue, $alpha: 0.04) !important;
      }
    }

    &-date {
      display: flex;
      justify-content: space-between;

      .width-2 {
        width: 54%;
      }

      input {
        // width: 48% !important;
        // margin-right: 1rem;
      }
    }

    // .creat-event__button {
    //   width: 30%;
    // }
  }
}
