.AvatarIMGMini {
  display: flex;
  width: 1.8rem;
  height: 1.8rem;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: darkgrey;
  /* background-size: 15vw; */
  z-index: -1;
}

.AvatarRadarMini {
  display: flex;
  align-items: center;
  justify-content: center;
  background: url(../../assets/img/borda_avatar.webp);
  background-size: cover;
  width: 2.5rem;
  min-width: 2.5rem;
  height: 2.5rem;
  margin-right: 5px;
  z-index: 1;
}
