.walletPage {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.walletContainerLeft {
  width: 35%;
  height: 100%;
}

.NavBarWallet {
  width: 100%;
  height: 100%;
}

.coinsContainer {
  height: 30%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  font-family: gilroy;
}

.coinsContainer i {
  color: var(--yellow);
}

.coinsContainer .infoWallet {
  margin-left: 25px;
}

.coinsContainer .coinsWallet {
  font-size: 3rem;
  color: var(--yellow);
  font-family: gilroy-thin;
  font-weight: bold;
  font-style: italic;
  transition: 0.2s;
  cursor: default;
}

.coinsContainer .coinsWallet:hover {
  text-shadow: 0 0 10px var(--yellow);
}

.coinsContainer .saldoWallet {
  font-size: 2rem;
  color: var(--pink);
  font-style: italic;
  line-height: 1.5;
}

.walletBtnContainer {
  height: 70%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.walletNavBtn {
  width: 24rem;
  min-width: 25rem;
  height: 4rem;
  border: 4px solid var(--lightblue);
  border-radius: 1.3rem;
  background-color: transparent;
  padding: 0;
  outline: none;
  cursor: pointer;
  transition: 200ms ease-in-out;
  outline: none;
  font-family: gilroy-medium;
  font-size: 1.6rem;
  font-weight: bold;
  letter-spacing: 1px;
  margin: 15px 0px;
}

.walletNavBtn:hover {
  box-shadow: 0 0 15px rgba(42, 193, 216, 0.5), inset 0 0 15px rgba(42, 193, 216, 0.5);
  color: var(--pink2);
  text-shadow: 0 0 15px var(--pink2);
  background: rgba(0, 0, 0, 0.2);
}

.walletContainerRight {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 65%;
  height: 80%;
  text-align: center;
  position: relative;
}

.SelectPlayer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 400px;
  background: rgba(0, 0, 0, 0.8);
  backdrop-filter: blur(7px);
  border-radius: 8px;
  padding: 10px;
}

.tabela {
  margin: 10px;
  width: 97%;
}

.PlayersList {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 30px;
}

.PlayersList button {
  background-color: black;
  border-radius: 8px;
  height: 25px;
  border: none;
}

.walletContainerRight-title {
  font-weight: bold;
  font-size: 2.5rem;
  position: absolute;
  top: -4rem;
  letter-spacing: 1px;
  opacity: 0.9;
}

.walletContainerRight-title::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 4px;
  background: var(--lightblue);
  border-radius: 2px;
  opacity: 0.7;
}

.walletContainerRight-trc {
  width: 100%;
  height: 100%;
}

@media (max-width: 980px) {
  .walletPage {
    flex-direction: column;
  }
  .walletContainerLeft {
    width: 100%;
    height: 70%;
  }
  .walletContainerRight {
    width: 100%;
    height: 30%;
  }
  .walletNavBtn {
    width: 300px;
    height: 50px;
  }
}
