.container-side-navbar {
  min-width: 400px;
  max-width: 400px;
  height: 100vh;

  @include mobile {
    max-width: 100vw;
  }
}

%background-size {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.PLAYER {
  background-image: url(../../assets/img/bg3.webp);
  @extend %background-size;
}

.STAKER {
  background-image: url(../../assets/img/bg2.webp);
  @extend %background-size;
}

.ORGANIZADOR {
  background-image: url(../../assets/img/bg1.webp);
  @extend %background-size;
}

.playerIconSideBar {
  background-image: url(../../assets/img/playerIcon.webp);
  width: 80px;
  height: 80px;
  @extend %background-size;
}

.stakerIconSideBar {
  background-image: url(../../assets/img/stakerIcon.webp);
  width: 80px;
  height: 80px;
  @extend %background-size;
}

.evoIconSideBar {
  background-image: url(../../assets/img/evoIcon.webp);
  width: 80px;
  height: 80px;
  @extend %background-size;
}

.navBar__side {
  height: 10%;
  display: flex;
  justify-content: space-between;
}

.infoUser__nav {
  height: 100%;
  display: inherit;
  flex-direction: column;
  justify-content: center;
  margin-left: 15px;
}

.btnsUser__Nav {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  user-select: none;
  font-family: gilroy-thin;
}

.btnsUser__Nav button {
  font-family: gilroy-thin;
}

.btnsUser__Nav button:hover {
  color: $pink;
}

.btnsUser__Nav .myDataBtn__sideNav {
  border: none;
  outline: none;
  background: transparent;
  cursor: pointer;
  font-size: 1rem;
  transition: 0.2s;
  letter-spacing: 1px;
  margin-right: 12px;
}

.btnsUser__Nav .exitBtn_sideNav {
  border: none;
  outline: none;
  background: transparent;
  cursor: pointer;
  font-size: 1rem;
  padding: 0;
  transition: 0.2s;
  letter-spacing: 1px;
  margin-left: 12px;
}

.btnsUser__Nav .exitBtn_sideNav:hover {
  color: $pink2;
}

.wallet__nav {
  width: 35%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  text-align: end;

  &-center {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    cursor: pointer;
    margin-right: 1rem;

    i {
      color: $yellow;
      margin-bottom: 5px;
    }
  }

  &-saldo {
    display: block;
    font-size: 1.1rem;
    font-style: italic;
    color: $pink;
  }

  &-coins {
    font-size: 1.4rem;
    color: $yellow;
    font-family: gilroy-thin;
    font-weight: bold;
    transition: 250ms ease-in-out;
    line-height: 1.1;
  }

  &:hover &-coins {
    text-shadow: 0 0 10px $yellow;
  }
}

.notifications__sideNav {
  width: 100%;
  height: 15%;
  border-bottom-left-radius: 0.6rem;
  border-bottom-right-radius: 0.6rem;
  background-color: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(10px);
}

.container-buttons-sideNav {
  width: 100%;
  height: 45%;
  display: flex;
  flex-direction: column;
  align-items: center;
  user-select: none;
}

.buttons-box {
  display: flex;
  justify-content: center;
  width: 100%;
}

.graphic__sideNav {
  width: 100%;
  height: 25%;
}

.container-button-profiles {
  width: 100%;
  height: 20%;
  display: grid;
  place-items: center;
}
