.modal-settings {

    &-slider {
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 1;

    }

    .slider-content {
        width: 300px;
        height: 0px;
        opacity: 0;
        padding: .6rem .8rem;
        margin-left: 5px;
        background: rgba($color: #000000, $alpha: 0.5);
        backdrop-filter: blur(5px);
        transition: 250ms $cubic-bezier-buttons;
        border-radius: 22px;
        border: none;
        overflow: hidden;

        .volume-content {
            display: flex;
            align-items: center;
            height: 2rem;

            &-control {
                display: flex;
                align-items: center;
                width: 100%;

                i {
                    margin-right: .8rem;
                    cursor: pointer;
                    color: rgba($color: #eee, $alpha: 0.4);
                    transition: color 125ms ease-in-out;
                    opacity: .8;
                }

                i:nth-child(3) {
                    margin-right: 0;
                    margin-left: .8rem;
                }

                .volume {
                    -webkit-appearance: none;
                    appearance: none;
                    height: 5px;
                    width: 100%;
                    border-radius: 3px;
                    opacity: .6;
                    transition: opacity 250ms ease;

                    &:hover {
                        opacity: 1;
                    }

                    &::-webkit-slider-thumb {
                        -webkit-appearance: none;
                        appearance: none;
                        height: 1rem;
                        width: 1rem;
                        background: $pink;
                        border-radius: 50%;
                        box-shadow: 0 0 3px #111;
                        cursor: pointer;
                    }
                }
            }
        }

        .language-content {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            margin-top: .5rem;

            p {
                font-size: 1.2rem;
                margin-right: 2rem;
                letter-spacing: 1px;
            }

            .select-language {
                @include default-input($w: 6rem);
            }
        }
    }

    .slider-active {
        height: 120px;
        opacity: 1;
    }

    .button-settings {
        width: 70px;
        height: 70px;
        display: grid;
        place-items: center;

        i {
            cursor: pointer;
            transition: 250ms ease-in-out;
        }

        .active {
            transform: rotate(30deg);
        }

        .activeBack {
            transform: rotate(-30deg);
        }
    }
}