.menu {
  display: flex;
  width: 100vw;
  flex-grow: 1;
  height: 10vh;
  justify-content: space-between;
  z-index: 10000;

  &-left {
    display: flex;
    align-items: center;
  }

  &-right {
    display: flex;
    align-items: center;
    padding-right: 0.8vw;

    @include large-device {
      padding-right: 2vw;
    }

    &-wallet {
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;

      &-infos {
        width: 100%;
        display: flex;
        flex-direction: column;
        text-align: end;
        font-size: 1.35rem;

        i {
          color: $yellow;
        }

        &-coins {
          font-size: clamp(1.2rem, 1.5vw, 2.8rem);
          color: $yellow;
          line-height: 1;
          transition: $cubic-main;
          white-space: nowrap;

          &:hover {
            text-shadow: 0 0 8px currentColor;
          }
        }
      }
    }

    // &-contact {
    //   display: flex;
    //   align-items: center;

    //   p {
    //     color: $pink;
    //     font-size: clamp(1.2rem, 1.5vw, 2.8rem);
    //     font-style: italic;
    //     white-space: nowrap;

    //     @include large-device {
    //       margin-right: 1vw;
    //     }
    //   }
    // }
  }
}

.btnEnterNavbar {
  width: 7.5rem;
  height: 3.125rem;
  border: none;
  border-radius: 15px;
  margin-right: 25px;
  background-color: transparent;
  font-weight: bold;
  font-size: 16px;
  z-index: 0;
  outline: none;
  position: relative;
  cursor: pointer;
}

.btnEnterNavbar:active {
  color: #111;
}

.btnEnterNavbar:before {
  content: "";
  background: linear-gradient(45deg, #28bed5, #175f8f, #4c1474, #ff28f1, #28bed5, #175f8f, #4c1474, #ff28f1, #28bed5);
  position: absolute;
  top: -3px;
  left: -3px;
  background-size: 400%;
  z-index: -1;
  filter: blur(4px);
  width: calc(100% + 6px);
  height: calc(100% + 6px);
  animation: glowing-rgb 20s linear infinite;
  opacity: 0.4;
  transition: opacity 0.3s ease-in-out;
  border-radius: 15px;
}

.btnEnterNavbar:hover:before {
  opacity: 1;
}

.btnEnterNavbar:after {
  z-index: -1;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: #111;
  left: 0;
  top: 0;
  border-radius: 15px;
}
