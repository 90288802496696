.page-player {
  display: flex;
  width: 100%;
  height: 100%;
  min-height: 600px;
  overflow-x: hidden;

  @include large-device {
    flex-direction: column;
    align-items: center;
  }

  @include mobile {
    min-height: 400px;
    overflow: hidden;
  }

  &-left {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
    height: 100%;

    @include large-device {
      width: 80%;
      height: 90vh;
    }

    @include medium-device {
      width: 80%;
    }

    // @include tablets {
    //   width: 100%;
    // }
  }

  &-right {
    width: 50%;
    height: 100%;
    position: relative;

    @include large-device {
      width: 100%;
      height: 100vw;
    }

    &-list {
      width: 100%;
      height: 80%;

      @include large-device {
        display: flex;
        justify-content: center;
      }
    }

    &-deck {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 20%;
      width: 100%;
      padding-left: 2rem;
      overflow-x: auto;
      overflow-y: hidden;
      position: relative;

      h2 {
        font-family: gilroy-bold;
        font-size: 1.2rem;
        line-height: 1;
        margin-bottom: 1rem;
      }

      //   @include large-device {
      //     margin-top: 1.5rem;
      //   }

      %buttons-deck-size {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        width: 75px;
        height: 75px;
        font-size: 0.8rem;
      }

      &-buttons {
        display: flex;
        position: relative;

        .button-deck {
          @extend %buttons-deck-size;
          margin-right: 0.8rem;
        }
      }

      .button-deck-add {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        width: 95px;
        height: 95px;
        // aspect-ratio: 1;
        font-size: clamp(0.7rem, 1vw, 0.8rem);
        white-space: pre-wrap;

        i {
          font-size: 1.5rem;
          margin-bottom: 2px;
        }
      }
    }
  }

  &-profile-mobile {
    width: 100%;
    height: 30%;
  }

  &-list-mobile {
    position: relative;
    width: 100%;
    height: 70%;
  }
}
