.onDemand-Page {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .-Tittle {
    position: absolute;
    top: 2rem;
    left: 50%;
    transform: translate(-50%);
  }

  .inputSearch {
    margin: 2rem;
    max-width: 40vw;
    min-width: 400px;
  }

  .-btnActive {
    display: flex;
    button {
      height: 50px;
      min-width: 225px;
      border: none;
      border-top-left-radius: 15px;
      border-top-right-radius: 15px;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  .-TopBar {
    width: 80%;
    height: 20%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    padding: 1rem;

    .-Inputs {
      display: block;
      margin: 1rem;
      width: 100%;

      .onDemandInput {
        &-Name {
          width: 40vw;
        }
        &-Password {
          width: 15vw;
        }
        &-Value {
          width: 10vw;
        }
      }

      .-PassCheck {
        display: flex;
        position: relative;
        i {
          position: absolute;
          right: 1rem;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }
    .buttonOnDemand {
      margin-top: 1.75rem;
      width: 15vw;
    }
  }
  .-BottomBar {
    width: 100%;
    height: 80%;
    display: flex;
    justify-content: center;

    .findGameTable {
      align-items: flex-start;
      justify-content: center;
      border-radius: 20px;
      overflow: auto;
      display: flex;
      height: 100%;
      width: 90%;

      table {
        width: 100%;

        .t-name {
          width: 55%;
          font-size: 1.5em;
        }
        .t-owner {
          width: 15%;
          font-size: 1.5em;
        }
        .t-value {
          width: 10%;
          font-size: 1.5em;
        }
        .t-lock {
          width: 10%;
          font-size: 1.5em;
          text-align: center;
        }
        .t-btn {
          width: 10%;
        }
        tr {
          max-height: 30px;
          th {
            max-height: 20px;
          }
        }
      }
    }
  }
}
