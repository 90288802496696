.pageMessage {
  width: 40vw;
  position: absolute;
  top: 0;
  // left: 23%;
  overflow: hidden;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  transition: ease 0.4s;
  z-index: 100;
  padding: 10px;
  font-family: gilroy;

  .msgBlock {
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-start;
    justify-content: flex-start;
    transition: ease 0.4s;
    width: 100%;
    height: 100%;
    letter-spacing: 1px;
  }

  .msgBlock span:nth-child(1) {
    color: var(--pink);
    font-weight: bold;
    letter-spacing: 1px;
    font-family: gilroy-medium;
  }

  .msgBlock span:nth-child(2) {
    font-weight: normal;
    font-family: gilroy-thin;
  }

  .msgBlock span:nth-child(3) {
    letter-spacing: 0.5px;
    transition: 250ms ease-in-out;
  }

  .msgBlock span:nth-child(3):hover {
    color: var(--pink2);
  }

  .msgBlock p {
    padding: 3px;
    cursor: pointer;
  }

  .degradeMsg {
    position: absolute;
    width: 100%;
    height: 100%;
    margin: 0;
    margin-left: -20px;
    margin-top: -20px;
    padding-right: 20px;
    padding-bottom: 20px;
    transition: all ease 0.4s;
  }
}

/* 
@media only screen and (max-width: 1200px) {
  .pageMessage {
  }
}

@media only screen and (max-width: 600px) {
  .pageMessage {
  }
}

@media only screen and (max-width: 425px) {
  .pageMessage {
    left: 20%;
    width: 25%;
  }
} */
