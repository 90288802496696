.room-card-player {
  width: 280px;
  aspect-ratio: 1;
  border-radius: 50%;
  transition: all 250ms ease-in-out;
  position: relative;

  @include extra-large-device {
    transform: scale(0.7);
  }

  @include large-device {
    transform: scale(0.55);
  }
}

.room-card-player .front {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  border-radius: 50%;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.4);
  transform: rotateY(0deg);
  transition: 0.5s;
  /* border-radius: 15px; */
}

.room-card-player .front img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.room-card-player .front .details-avatar-front {
  position: absolute;
  top: -15%;
  left: -20%;
  width: 130px;
  height: 130px;
  background: #fff;
  border-radius: 50%;
  padding: 5px;
  max-width: 100%;
  max-height: 100%;
  border-radius: 50%;
  object-fit: cover;

  &-readyBox {
    position: absolute;
    border-radius: 5px;
    top: 1rem;
    // background: rgba($color: #000000, $alpha: .4);
    padding: 0.4rem;
    transform: skewX(-10deg);

    p {
      color: $light-green;
      font-size: 1.4rem;
      font-family: gilroy-bold;
      // font-style: italic;
    }
  }

  &-status {
    position: absolute;
    top: -5%;
    left: 50%;
    transform: translateX(-50%);
    z-index: 3000;
    font-family: gilroy-bold;
    font-style: 0.8rem;
    white-space: nowrap;
    background: #333;
    padding: 0.1rem 0;
    width: 70%;
    text-align: center;

    &::after {
      content: "";
      position: absolute;
      left: -20%;
      top: 20%;
      width: 20%;
      height: 100%;
      background: #222;
      clip-path: polygon(100% 0, 100% 50%, 100% 100%, 0% 100%, 25% 50%, 0% 0%);
    }

    &::before {
      content: "";
      position: absolute;
      right: -20%;
      top: 20%;
      width: 20%;
      height: 100%;
      background: #222;
      clip-path: polygon(100% 0%, 75% 50%, 100% 100%, 0 100%, 0% 50%, 0 0);
    }
  }
}

.room-card-player .front .details-avatar-front img {
  border-radius: 50%;
}

/* deletar apos ajustar */

.room-card-player .front {
  transform: rotateY(0deg);
}

.detailsLoading {
  position: absolute;
  text-align: center;
  top: 50%;
  left: 0;
  width: 100%;
}

.room-card-player .backCardIMG {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  /* backface-visibility: hidden; */
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.4);
  transform: rotateY(0deg);
  transition: 0.5s;
  border-radius: 50%;
}

.room-card-player .backCardIMG {
  transform: rotateY(-180deg);
  background-image: url("../../assets/img/bg7.webp");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.4);
  border-radius: 50%;
}

.room-card-player .backCardIMG::after {
  content: "";
  position: absolute;
  top: -5%;
  left: -13%;
  width: 110%;
  height: 115%;
  border-radius: 50%;
}

.backContent {
  position: absolute;
  top: 6%;
  left: 30%;
}

.gradientNickBack {
  font-size: 2rem;
  font-weight: bold;
  font-family: gilroy-medium;
  font-style: italic;
  letter-spacing: 0.23em;
  background: -webkit-linear-gradient(left, #2ac1d8, #ff28f1);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.gradientNickFront {
  font-family: gilroy-medium;
  letter-spacing: 1rem;
  background: -webkit-linear-gradient(left, #2ac1d8, #ff28f1);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

/* .card:hover .backCardIMG {
      transform: rotateY(-180deg);
  } */

/* deletar apos ajustar */

/* 
  .card:hover .front {
      transform: rotateY(180deg);
  } */

.room-card-player .back {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background-image: url('../../assets/img/MagicCardFront.webp'); */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.4);
  border-radius: 50%;
  backface-visibility: hidden;
  transform: rotateY(180deg);
  transition: 0.5s;
}

/* deletar apos ajustar */

/* .card .back {
      transform: rotateY(-180deg);
  } */

/* deletar apos ajustar */

/* .card:hover .back {
      transform: rotateY(0deg);
  } */

.room-card-player .back .details {
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 100%;
  text-align: center;
  letter-spacing: 1px;
}

.room-card-player .back .details img {
  position: absolute;
  object-fit: contain;
  width: 100%;
  border-radius: 50%;
}

.room-card-player .back .details .cardLogo {
  background-image: url("../../assets/img/branca3.webp");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  width: 35px;
  height: 20px;
  top: 56.5%;
  right: 6%;
  filter: invert(100%);
}

.room-card-player .back .details .details-avatar {
  position: absolute;
  top: -50px;
  width: 100px;
  height: 100px;
  border-radius: 15px;
  background: #fff;
  padding: 5px;
}

/* .card .back .details .details-avatar img {
      max-width: 100%;
      max-height: 100%;
      border-radius: 10px;
      object-fit: cover;
  } */

.room-card-player .back .textDetails h1 {
  font-size: 3rem;
  position: absolute;
  text-align: center;
  width: 105%;
  top: -20%;
  left: 0;
}

.room-card-player .back .details p {
  font-weight: bold;
  font-family: MagicCard;
  color: black;
}

.room-card-player .back .details .textType {
  font-size: 0.9rem;
  font-weight: bold;
  font-family: MagicCard;
  font-stretch: condensed;
  position: absolute;
  color: black;
  bottom: 38.5%;
  left: 9%;
}

.room-card-player .back .details .textName {
  font-size: 1rem;
  font-family: MagicCard;
  font-weight: 1;
  position: absolute;
  bottom: 28%;
  left: 10%;
}

.room-card-player .back .details .textDescription {
  font-size: 0.9rem;
  font-family: MagicCard;
  font-style: italic;
  font-weight: 1;
  position: absolute;
  bottom: 15%;
  left: -2%;
}

.room-card-player .back .details .textScore {
  font-size: 1.1rem;
  font-weight: bold;
  font-family: MagicCard2;
  position: absolute;
  color: black;
  bottom: 4.7%;
  right: 11%;
}

.room-card-player .back .details span {
  font-size: 1.5rem;
  color: #cdc9c3;
}

.social-iconsCard {
  position: absolute;
  top: 30%;
  left: -20%;
  /* bottom: -60px; */
}

.social-iconsCard a {
  display: inline-block;
  background: transparent;
  border-radius: 50%;
  width: 40px;
  aspect-ratio: 1;
  margin: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  transition: 200ms ease-in-out;
}

.social-iconsCard a:nth-child(1) {
  position: relative;
  top: 0%;
  left: 8%;
}

.social-iconsCard a:nth-child(2) {
  position: absolute;
  top: 100%;
  left: 12%;
}

.social-iconsCard a:nth-child(3) {
  position: absolute;
  top: 201%;
  left: 54%;
}

.social-iconsCard a:nth-child(4) {
  position: absolute;
  top: 275%;
  left: 137%;
}

.social-iconsCard a:nth-child(1):hover {
  background: #3b5998;
  color: #fff;
}

.social-iconsCard a:nth-child(2):hover {
  background: #6441a5;
  color: #fff;
}

.social-iconsCard a:nth-child(3):hover {
  background: #f00;
  color: #fff;
}

.social-iconsCard a:nth-child(4):hover {
  background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285aeb 90%);
  color: #fff;
}

.social-iconsCard i {
  color: #fff;
}

// ----------------------------------------- //
// MOBILE //
// ----------------------------------------- //

.card-player-mobile {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: url("../../assets/img/bg7.webp") no-repeat;
  background-position: center;
  background-size: cover;

  &-avatar {
    position: relative;
    top: -5%;
    left: -10%;
    display: grid;
    place-items: center;
    width: 38%;
    height: 38%;

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: contain;
      border: 2px solid #fff;
      border-radius: 50%;
    }

    h2 {
      position: absolute;
      left: 110%;
      font-size: 5.5vw;
    }
  }

  &-orb {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: grid;
    place-items: center;

    .deck-orb-mobile {
      margin-bottom: -25%;
    }
  }

  &-social {
    a {
      position: absolute;
      width: 15%;
      height: 15%;
      cursor: pointer;
      border-radius: 50%;
      display: grid;
      place-items: center;
      transform: translateX(50%);

      i {
        position: absolute;
        font-size: 3vw;
      }
    }

    a:nth-child(1) {
      background: #3b5998;
      color: #fff;
      left: -27%;
    }

    a:nth-child(2) {
      background: #6441a5;
      color: #fff;
      top: 62%;
      left: -24%;
    }

    a:nth-child(3) {
      background: #f00;
      color: #fff;
      top: 81%;
      left: -13%;
    }

    a:nth-child(4) {
      background: $insta;
      color: #fff;
      top: 96%;
      left: 5%;
    }
  }

  &-confirm-flag {
    pointer-events: none;
    position: absolute;
    bottom: 0;
    right: 0;
    width: 30%;
    height: 30%;
    background: $lightblue;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    border-radius: 50%;

    h3 {
      font-size: 2.2vw;
      text-transform: uppercase;
      line-height: 1.16;
    }
  }
}

.waiting-banner {
  display: flex;
  align-items: center;
  text-align: center;
  width: 100%;
  height: 100%;

  h3 {
    white-space: normal;
    font-size: 5vw;
  }
}
