// ------------------------------MIXINS-------------------------------------- //

@mixin deck-add-button {
  @include default-button($scale: 0.8, $border-r: 50%, $font-s: 0.8rem);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0;
  width: 6rem;
  height: 6rem;
  white-space: normal;
  line-height: 1rem;

  i {
    margin-bottom: 5px;
    font-size: 1.5rem;
  }
}

@mixin default-effect-button($font-s: clamp(0.8rem, 1vw, 1.4rem), $font-w: bold, $scale: 1, $border-r: 14px) {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  outline: none;
  padding: 0.6rem;
  border: 4px solid $pink;
  border-radius: $border-r;
  text-transform: uppercase;
  text-align: center;
  font-size: $font-s;
  font-weight: $font-w;
  letter-spacing: 1px;
  white-space: nowrap;
  cursor: pointer;
  background-color: rgba($color: #000000, $alpha: 0.1);
  transform: scale($scale);
  position: relative;
  transition: transform 300ms $cubic-bezier-buttons, border-color 300ms $cubic-bezier-buttons;

  @include large-device {
    border: 2px solid $pink;
  }

  &:hover {
    transform: scale($scale + 0.05);
    border-color: transparent;
  }

  &::before {
    content: "";
    background: linear-gradient(to right, #28bed5, #175f8f, #4c1474, #ff28f1, #28bed5, #175f8f, #4c1474, #ff28f1, #28bed5);
    background-size: 400%;
    position: absolute;
    top: -3px;
    left: -3px;
    z-index: -1;
    filter: blur(5px);
    width: calc(100% + 6px);
    height: calc(100% + 6px);
    animation: glowing-rgb 20s linear infinite alternate;
    border-radius: $border-r;
    opacity: 1;
    transition: opacity 300ms $cubic-bezier-buttons;
    transform: scaleX(1);
    transition: transform 300ms $cubic-bezier-buttons;
  }

  &:hover::before {
    opacity: 1;
    transform: scaleX(1);
  }

  &::after {
    content: "";
    width: 100%;
    height: 100%;
    background: $pink;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    transform: scaleX(0);
    transition: 300ms $cubic-bezier-buttons;
    border-radius: 12px;
  }

  &:hover::after {
    transform: scaleX(1);
    border-radius: $border-r;
    animation: opacity-animation 500ms ease 500ms forwards;
  }
}

@mixin default-button($font-s: clamp(0.8rem, 1vw, 1.4rem), $font-w: bold, $scale: 1, $border-r: 14px) {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  outline: none;
  padding: 0.6rem;
  border: 4px solid $pink;
  border-radius: $border-r;
  text-transform: uppercase;
  text-align: center;
  font-size: $font-s;
  font-weight: $font-w;
  letter-spacing: 1px;
  white-space: nowrap;
  cursor: pointer;
  background-color: rgba($color: #000000, $alpha: 0.1);
  transform: scale($scale);
  position: relative;
  transition: transform 300ms $cubic-bezier-buttons, border-color 300ms $cubic-bezier-buttons;

  @include large-device {
    border: 2px solid $pink;
  }

  &:hover {
    transform: scale($scale + 0.05);
    border-color: transparent;
  }

  &::before {
    content: "";
    background: linear-gradient(to right, #28bed5, #175f8f, #4c1474, #ff28f1, #28bed5, #175f8f, #4c1474, #ff28f1, #28bed5);
    background-size: 400%;
    position: absolute;
    top: -3px;
    left: -3px;
    z-index: -1;
    filter: blur(5px);
    width: calc(100% + 6px);
    height: calc(100% + 6px);
    animation: glowing-rgb 20s linear infinite alternate;
    border-radius: $border-r;
    opacity: 0;
    transition: opacity 300ms $cubic-bezier-buttons;
    transform: scaleX(0);
    transition: transform 300ms $cubic-bezier-buttons;
  }

  &:hover::before {
    opacity: 1;
    transform: scaleX(1);
  }

  &::after {
    content: "";
    width: 100%;
    height: 100%;
    background: $pink;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    transform: scaleX(0);
    transition: 300ms $cubic-bezier-buttons;
    border-radius: 12px;
  }

  &:hover::after {
    transform: scaleX(1);
    border-radius: $border-r;
    animation: opacity-animation 500ms ease 500ms forwards;
  }
}

@mixin rgb-button($font-s: 1.2rem, $font-w: bold, $border-r: 34px) {
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  position: relative;
  cursor: pointer;
  padding: 0.6rem 1.2rem;
  border-radius: $border-r;
  border: none;
  outline: none;
  font-weight: $font-w;
  font-size: $font-s;
  text-transform: uppercase;
  letter-spacing: 1px;
  background-color: transparent;
  transition: 250ms ease;

  &::before {
    content: "";
    background: linear-gradient(45deg, #28bed5, #175f8f, #4c1474, #ff28f1, #28bed5, #175f8f, #4c1474, #ff28f1, #28bed5);
    background-size: 400%;
    position: absolute;
    top: -3px;
    left: -3px;
    z-index: -1;
    filter: blur(4px);
    width: calc(100% + 6px);
    height: calc(100% + 6px);
    animation: glowing-rgb 20s linear infinite alternate;
    border-radius: $border-r;
  }

  &::after {
    content: "";
    width: 100%;
    height: 100%;
    background-color: #000;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    border-radius: $border-r;
  }
}

// ------------------------------CLASS-NAMES-------------------------------------- //

// -------------------- //
// default-button //
// -------------------- //

.default-button {
  @include default-button;
}

// -------------------- //
// login
// -------------------- //

.button-login {
  @include default-button;
  width: 98%;
  margin: 1rem 0;
}

// -------------------- //
// navbar
// -------------------- //

.rowback {
  width: clamp(3.2rem, 3.75vw, 5rem);
  height: 75%;
  border: none;
  background-color: transparent;
  transition: 250ms ease-in-out;
  cursor: pointer;
  outline: none;

  i {
    transition: color 250ms ease-in-out;
    font-size: clamp(1.5rem, 1.6vw, 3rem);
  }

  &:hover i {
    color: $pink;
  }

  @include mobile {
    display: none;
  }
}

.button-side-navbar {
  width: clamp(4rem, 4vw, 6rem);
  height: clamp(2.8rem, 5.9vh, 3.8rem);
  max-height: 55px;
  margin-left: 0.5vw;
  @include rgb-button($border-r: 15px);

  @include mobile {
    margin-left: 3vw;
  }

  i {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    font-size: clamp(1.6rem, 1.6vw, 3rem);
  }
}

.button-wpp {
  width: clamp(2.8rem, 3.2vw, 4.05rem);
  height: clamp(2.8rem, 3.2vw, 4.05rem);
  margin-left: 1vw;
  @include rgb-button($border-r: 15px);

  i {
    font-size: clamp(1.6rem, 1.8vw, 3rem);
  }
}

// -------------------- //
// side-navbar
// -------------------- //

.button-home {
  margin: 10px 0 10px 0;
  width: 22.5rem;
  @include default-button($border-r: 14px);
}

.button-sideNav {
  margin: 10px 12px;
  width: 10.2rem;
  @include default-button($border-r: 14px, $font-s: 100%, $scale: 1.05);
}

.button-profiles-sideNav {
  padding: 0 0.8rem 0 0.2rem;
  width: 22rem;
  height: 5.8rem;
  border: none;
  border-radius: 2rem;
  outline: none;
  background: $bg-button-profiles;
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  text-decoration: none;
  box-shadow: 0px 7px 15px rgba(0, 0, 0, 0.5);
  transition: all 175ms ease-in-out;

  &:hover {
    transform: scale(1.02);
    box-shadow: 0px 14px 15px rgba(0, 0, 0, 0.4);
  }

  img {
    object-fit: contain;
  }

  p {
    letter-spacing: 1px;
  }

  &-content {
    pointer-events: none;
    display: flex;
    align-items: center;

    &-img {
      height: 100%;
      display: flex;
      align-items: center;
    }

    &-txt {
      width: 60%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;

      p {
        letter-spacing: 0.8px;
        white-space: nowrap;
      }

      h4 {
        font-size: 1.6rem;
        font-weight: bold;
      }
    }
  }
}

// -------------------- //
// welcome
// -------------------- //

%w-buttons {
  height: 90%;
  width: 90%;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;

  @include large-device {
    width: 100%;
    height: 100%;
  }
}

.w-button {
  // animation: bounceInUp forwards;
  // animation-duration: 500ms;
  align-items: center;
  background: linear-gradient(12deg, rgba(76, 20, 116, 1) 34%, rgba(23, 95, 143, 1) 65%, rgba(42, 193, 216, 1) 95%);
  border: none;
  border-radius: 28px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  font-size: clamp(1.4rem, 1.7vw, 3rem);
  font-family: Gilroy-medium;
  font-weight: bold;
  width: clamp(8.5rem, 9.1vw, 14rem);
  height: clamp(10rem, 21vh, 20rem);
  letter-spacing: 2px;
  outline: none;
  padding: 10px;
  text-decoration: none;
  text-align: center;

  @include large-device {
    max-height: 11rem;
  }

  @include mobile {
    max-width: 28vw;
    max-height: 18vh;
    font-size: clamp(1.2rem, 1.5vw, 3rem);
    border-radius: 18px;
  }

  &-icon-player {
    @extend %w-buttons;
    background-image: url("../../assets/img/playerIcon.webp");
  }

  &-icon-staker {
    @extend %w-buttons;
    background-image: url("../../assets/img/stakerIcon.webp");
  }

  &-icon-evo {
    @extend %w-buttons;
    background-image: url("../../assets/img/evoIcon.webp");
  }
}

.button-evo {
  font-size: clamp(1rem, 1.2vw, 3rem);
  line-height: 1;

  @include mobile {
    font-size: clamp(0.8rem, 1.2vw, 3rem);
  }
}

.translate-y {
  transition: transform 200ms ease-in-out;

  &:hover {
    transform: translateY(-3vh);

    @include mobile {
      transform: none;
    }
  }
}

// -------------------- //
// room-rounds
// -------------------- //

.button-round {
  width: 2vw;
  min-width: 30px;
  aspect-ratio: 1;
  margin-bottom: 10px;
  padding: 0 !important;
  @include default-button($border-r: 50%, $font-s: 1.2rem);

  // @include temporary-device {
  //   width: 30px;
  // }

  &:hover {
    transform: scale(1) !important;
  }
}

.button-effect-round {
  aspect-ratio: 1;
  width: 40px;
  margin-bottom: 10px;
  padding: 0 !important;
  @include default-effect-button($border-r: 50%, $font-s: 1.2rem);

  @include temporary-device {
    width: 30px;
  }

  &:hover {
    transform: scale(1) !important;
  }
}

.button-round-confirm {
  @include default-button($border-r: 18px, $font-s: 2vw);
  // position: absolute !important;
  // margin-top: 2%;
  animation-duration: 500ms;

  &:hover {
    transform: scale(1) !important;
  }
}

// -------------------- //
// list //
// -------------------- //

.button-enter-tournament {
  padding: 0.4rem;
  background-color: purple;
  border-radius: 12px;
  border: none;
  cursor: pointer;
  font-size: 1rem;
  letter-spacing: 1px;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
  position: absolute;
  top: 0;
  right: 0;
  transition: 125ms ease-in-out;

  &:hover {
    background-color: $pink2;
  }

  @include mobile {
    font-size: 0.8rem;
    border-radius: 6px;
    padding: 0.3rem;
  }

  @include extra-small-mobile {
    font-size: 0.5rem;
  }
}

// -------------------- //
// evo //
// -------------------- //

.svg-button {
  width: clamp(1.2rem, 1.7vw, 2.1rem);
  height: clamp(1rem, 2.4vh, 2.4rem);
}

.cta {
  display: flex;
  padding: 1vh 1.8vw;
  text-decoration: none !important;
  font-family: Gilroy-medium;
  font-size: clamp(0.8rem, 1.375vw, 1.4rem);
  align-items: center;
  color: white;
  background: #111;
  box-shadow: 3px 3px 0 black;
  border-radius: 35px;
  cursor: pointer;
  position: absolute;
  bottom: 3vw;
  right: 5vw;
  transition: 1s;

  @include mobile {
    padding: 0.3vh 2vw;
  }

  &:focus {
    outline: none;
  }

  &:hover {
    box-shadow: 0.4vw 0.4vw 0 $lightblue;
  }

  span:nth-child(2) {
    position: relative;
    transition: 0.2s;
    margin-right: 0px;
    width: 5px;
    margin-left: 15px;
    top: 12%;
  }

  &:hover span:nth-child(2) {
    margin-right: 15px;
  }
}

/**************SVG****************/

path.one {
  transition: 0.4s;
  transform: translateX(-60%);
}

path.two {
  transition: 0.5s;
  transform: translateX(-30%);
}

.cta:hover path.three {
  animation: color_anim 1s infinite 0.2s;
}

.cta:hover path.one {
  transform: translateX(0%);
  animation: color_anim 1s infinite 0.6s;
}

.cta:hover path.two {
  transform: translateX(0%);
  animation: color_anim 1s infinite 0.4s;
}

// -------------------- //

.button-delete-deck {
  position: absolute;
  top: 2.5rem;
  right: 5px;
  z-index: 3000;
  width: 25px;
  height: 25px;
  border: none;
  border-radius: 50%;
  background: linear-gradient(45deg, #f00000 0%, #dc281e 100%);
  transition: 175ms ease-in-out;
  cursor: pointer;
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-family: gilroy-black;
}

// -------------------- //
// staker //
// -------------------- //

.button-stake {
  display: flex;
  justify-content: flex-end;
  position: relative;
  border: none;
  border-radius: 1.2vw;
  background: transparent;
  cursor: pointer;
  padding: 0.5rem 0.6rem;
  padding-right: 6.7vw;
  font-size: clamp(2.4rem, 7vw, 9rem);
  white-space: nowrap;
  transition: $cubic-main;

  &:hover {
    background: rgba($color: #000000, $alpha: 0.3);
  }

  &-img {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 6.5vw;
    background-image: url(../../assets/img/stakerIcon.webp);
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }
}
