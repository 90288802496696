// media queries

// extra small devices / extra small phones //
@mixin extra-small-mobile {
  @media only screen and (max-width: 350px) {
    @content;
  }
}

// small devices / small phones //
@mixin small-mobile {
  @media only screen and (max-width: 400px) {
    @content;
  }
}

// mobal devices / phones //
@mixin mobile {
  @media only screen and (max-width: 479px) {
    @content;
  }
}

// portrait tablets and large phones //
@mixin tablets {
  @media only screen and (max-width: 600px) {
    @content;
  }
}

// landscapes tablets and up //
@mixin medium-device {
  @media only screen and (max-width: 768px) {
    @content;
  }
}

// laptops/desktops //
@mixin large-device {
  @media only screen and (max-width: 1024px) {
    @content;
  }
}

// large desktops / laptops //
@mixin temporary-device {
  @media only screen and (max-width: 1200px) {
    @content;
  }
}

// large desktops / laptops //
@mixin extra-large-device {
  @media only screen and (max-width: 1400px) {
    @content;
  }
}

// --------------------------------------------------------- //
