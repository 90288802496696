.page-evo {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  overflow-y: hidden;

  @include large-device {
    flex-direction: column;
    min-height: 600px;
  }

  @include mobile {
    justify-content: flex-start;
  }

  &-bg {
    position: absolute;
    top: 0;
    left: 0;
    background: radial-gradient(52% 52% at 67% 48.55%, #2a004f 8.6%, #180a29 79.39%);
    filter: hue-rotate(278deg);
    margin: 0;
    height: 100%;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    z-index: -1;
  }

  &-left {
    width: 50%;
    height: 100%;

    @include large-device {
      width: 100%;
      height: 50%;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    @include mobile {
      height: 100%;
    }

    &-profile {
      width: 100%;
      height: 50%;

      @include large-device {
        width: 90%;
      }

      @include mobile {
        width: 100%;
        height: 30%;
      }
    }

    &-menuTab {
      width: 100%;
      height: 50%;

      @include mobile {
        height: 70%;
      }
    }
  }

  &-right {
    width: 45%;
    height: 110vh;

    @include large-device {
      width: 100%;
      height: 50%;
    }

    @include mobile {
      display: none;
    }
  }
}

.productImg {
  width: 60px;
  height: 60px;
  margin: 5px;
  border-radius: 50%;
}

.productImg:hover {
  transform: scale(3);
  margin-bottom: 50px;
}

/*********************************/

.organizerInfo {
  width: 100%;
  height: 50%;
}

.organizerGrid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  height: 100%;
  width: 100%;
}

.organizerSchedule {
  background-color: rgba(0, 0, 0, 0.2);
  width: 100%;
  height: 100%;
}

.organizerRules {
  background-color: rgba(0, 0, 0, 0.2);
  width: 100%;
  height: 100%;
}

.organizerContact {
  background-color: rgba(0, 0, 0, 0.2);
  width: 100%;
  height: 100%;
}
