.dialog {
    background: rgba(0, 0, 0, 0.1);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent !important;
    z-index: 10;
    /* border-radius: 20px; */
}

.dialog-box {
    background: #505050 !important;
    padding: 10px;
    display: flex;
    width: 300px;
    height: 100px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    outline: none;
}

.dialog-content {
    background: transparent !important;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* 
.dialog-content {
    display: block;
    align-items: center;
    justify-content: center;
} */

.dialog-action {
    display: flex;
    width: 100%;
    justify-content: space-between;
}
