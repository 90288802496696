.store-card {
    width: 320px;
    height: 450px;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 34px;
    padding: 10px;
    margin: 20px;
    display: grid;
    place-items: center;
    position: relative;
    overflow: hidden;

    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(54deg,
                rgba(42, 193, 216, 1) 4%,
                rgba(197, 2, 178, 1) 51%,
                rgba(76, 20, 116, 1) 92%);
        clip-path: circle(150px at 80% 20%);
        transition: 0.5s ease-in-out;
    }

    &:hover::before {
        clip-path: circle(300px at 80% -20%);
    }

    &::after {
        content: "Magic";
        position: absolute;
        top: 45%;
        left: -20%;
        font-size: 10em;
        font-style: italic;
        font-weight: 700;
        text-align: center;
        z-index: -1;
        color: rgba($color: #c502b2, $alpha: 0.05);
    }

    img {
        width: 100%;
        height: 220px;
        object-fit: contain;
        position: absolute;
        z-index: 10000;
        top: 50%;
        transform: translateY(-60%);
        transition: 0.5s;
    }

    &:hover img {
        top: 0%;
        transform: translateY(10%);
    }

    &:hover .store-card__description {
        height: 195px;
    }

    &:hover .store-card__description a {
        opacity: 1;
        transform: translateY(0px);
        transition-delay: 0.75s;
    }

    &:hover .store-card__description-footer {
        opacity: 1;
        visibility: visible;
        transition-delay: 0.5s;
    }
}

.store-card__description {
    position: absolute;
    bottom: 0;
    text-align: center;
    transition: 1s;
    z-index: 10;
    height: 100px;
    width: 100%;
    padding: 5px;

    h2 {
        position: relative;
        letter-spacing: 1px;
        font-size: 1.3rem;
    }

    p {
        margin: 8px;
        opacity: 0.9;
    }

    a {
        display: inline-block;
        padding: 10px 20px;
        background: #fff;
        border-radius: 4px;
        margin-top: 10px;
        text-decoration: none;
        font-weight: 600;
        color: #111;
        opacity: 0;
        transform: translateY(50px);
        transition: 0.5s;
        margin-top: 0;
        cursor: pointer;

        &:hover {
            background-color: #c502b2;
            transition-delay: 0s !important;
        }
    }

    &-footer {
        display: flex;
        justify-content: center;
        visibility: hidden;
        opacity: 0;
        transition: 0.5s;

        &__items {
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            margin: 20px 0;
            transition: 0.5s;

            h3 {
                position: absolute;
            }

            button {
                width: 26px;
                height: 26px;
                line-height: 26px;
                border: none;
                border-radius: 4px;
                color: #111;
                background-color: #fff;
                margin: 0 20px;
                font-size: 2rem;
                cursor: pointer;
                transition: 0.4s;

                &:hover {
                    background-color: #c502b2;
                }
            }
        }
    }
}