// ------------------------------MIXINS-------------------------------------- //

@mixin default-input($w: 100%, $border-r: 12px) {
  width: $w;
  font-size: clamp(1rem, 1.16vw, 1.6rem);
  letter-spacing: 1px;
  color: $pink2;
  padding: 0.8rem;
  border: 1px solid $purple;
  border-radius: $border-r;
  outline: none;
  background-color: rgba($color: #000000, $alpha: 0.2);
  transition: 0.3s;

  @include mobile {
    padding: .5rem;
  }

  &:focus {
    border-color: $pink;
    background: rgba($color: #000000, $alpha: 0.5);
  }

  &::placeholder {
    color: rgba($color: $pink, $alpha: 0.4);
  }
}

@mixin default-input-i($w: 100%, $border-r: 12px) {
  position: relative;

  input {
    width: $w;
    font-size: clamp(1rem, 1.16vw, 1.6rem);
    font-family: gilroy-medium;
    letter-spacing: 1px;
    color: $pink2;
    padding: 0.8rem;
    border: 1px solid $purple;
    border-radius: $border-r;
    outline: none;
    background-color: rgba($color: #000000, $alpha: 0.2);
    transition: all 250ms ease-in-out;

    @include mobile {
      padding: .6rem;
    }

    &:focus {
      border-color: $pink;
      background: rgba($color: #000000, $alpha: 0.5);
    }

    &:focus~i {
      color: $pink;
    }

    &::placeholder {
      font-family: gilroy;
      color: rgba($color: $pink, $alpha: 0.6);
    }
  }

  i {
    font-size: clamp(1rem, 1.2vw, 1.4rem);
    color: $purple;
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
    transition: 250ms ease-in-out;
  }
}

@mixin login-input($w: 100%) {
  background: #000;
  color: $pink;
  font-size: clamp(1rem, 1.2vw, 2rem);
  border: 2px solid $lightblue;
  border-radius: 15px;
  margin-bottom: 1rem;
  width: $w;
  padding: 1rem;
  outline: none;
  transition: transform 200ms ease-in-out;
  caret-color: $lightblue;

  @include mobile {
    padding: .8rem;
  }

  &:focus {
    transform: scale(1.05);
  }

  &::placeholder {
    color: rgba($color: $pink, $alpha: 0.6);
    letter-spacing: 1px;
  }
}

@mixin default-input-label {
  position: relative;

  &-content {
    position: relative;
    width: 100%;
    margin-bottom: 1rem;
    padding: .8rem 1rem;
    font-size: clamp(1rem, 1.2vw, 1.5rem);
    letter-spacing: 1px;
    background: black;
    background-clip: padding-box;
    border: 2px solid $lightblue;
    border-radius: 15px;
    outline: none;
    caret-color: $lightblue;

    @include mobile {
      padding: .6rem;
    }

    &::placeholder {
      font-style: italic;
      color: rgba($color: #fff, $alpha: 0.4);
    }
  }

  .input-label {
    position: absolute;
    z-index: 10;
    display: grid;
    place-items: center;
    top: -0.65rem;
    left: 1.7rem;
    font-size: clamp(.8rem, 1vw, 1.2rem);
    font-weight: bold;
    letter-spacing: 1px;
    color: $pink;
    cursor: text;
    opacity: .8;
    text-shadow: 0 0 7px $purple;
    pointer-events: none;
    white-space: nowrap;

    @include extra-small-mobile {
      font-size: clamp(.6rem, 1vw, 1.2rem);
      top: -0.5rem;
      left: 1.5rem;
    }
  }

  .input-label-line {
    width: 7.4rem;
    height: 2px;
    background: black;
    position: absolute;
    left: 1.1rem;
  }
}

// ------------------------------CLASS-NAMES-------------------------------------- //

// ----------------------- //
// default-input //
// ----------------------- //

.default-input {
  @include default-input;
}

.default-input-i {
  @include default-input-i;
}

.default-input-label {
  @include default-input-label;
}

// ----------------------- //
// login
// ----------------------- //
.inputLogin {
  @include login-input($w: 400px);
}

.form-div {
  position: relative;
  width: 100%;

  &-input {
    position: relative;
    width: 100%;
    margin-bottom: 1rem;
    padding: .8rem 1.2rem;
    font-size: clamp(1.2rem, 1.5vw, 2rem);
    letter-spacing: 1px;
    color: $pink;
    background: black;
    background-clip: padding-box;
    border: 2px solid $lightblue;
    border-radius: 15px;
    outline: none;
    caret-color: $lightblue;

    @include mobile {
      padding: .8rem 1rem;
    }

    &::after {
      content: '';
      position: absolute;
      background: black;
      width: 4rem;
      height: 1rem;
    }

    &:focus~.input-label,
    &:valid~.input-label {
      top: -0.65rem;
      left: 1.7rem;
      font-size: 1.1rem;
      pointer-events: none;
      text-shadow: 0 0 7px $purple;

      @include mobile {
        font-size: 1rem;
      }
    }

    &:focus~.input-label-line,
    &:valid~.input-label-line {
      transform: scaleX(1);
    }
  }

  .input-label {
    position: absolute;
    z-index: 10;
    display: grid;
    place-items: center;
    top: .85rem;
    left: 1.9rem;
    font-size: clamp(1.2rem, 1.5vw, 2rem);
    font-weight: bold;
    letter-spacing: 1px;
    color: $pink;
    user-select: none;
    cursor: text;
    opacity: .8;
    transition: all 200ms ease-in-out;
  }

  .input-label-line {
    width: 3.6rem;
    height: 3px;
    background: black;
    position: absolute;
    left: 1.1rem;
    transform: scaleX(0);
    transition: all 150ms ease-in-out;
  }

  .width-pass {
    width: 6.5rem;
  }

  .width-passC {
    width: 11rem;
  }
}