.topCenter {
  position: absolute;
  display: center;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  top: 0;
  right: 1rem;
  height: 12vh;
  font-size: 20px;
  z-index: 100;
}

.alert h1 {
  font-size: 1.1em;
}
