%photo-configs {
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;
  clip-path: url(#svgShield1);

  img {
    clip-path: url(#svgShield1);
    max-width: 90%;
    height: 90%;
    object-fit: cover;
    display: block;
  }
}

@mixin hof-card {
  height: 15.6rem;
  min-height: 15.6rem;
  width: 35rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 5px;
  border-radius: 24px;
  position: relative;

  &_avatar-container {
    position: relative;
    z-index: -1;
    width: 40%;
    height: 100%;
    display: grid;
    place-items: center;

    .photo-one {
      @extend %photo-configs;
      background: linear-gradient(300deg, rgba(76, 20, 116, 1) 30%, rgba(255, 230, 0, 1) 90%);
      background-size: 400%;
      animation: glowing-rgb 10s linear infinite;
    }

    .photo-two {
      @extend %photo-configs;
      background: linear-gradient(30deg, rgba(211, 211, 211, 1) 35%, rgba(169, 169, 169, 1) 65%);
      background-size: 400%;
      animation: glowing-rgb 10s linear infinite;
    }

    .photo-three {
      @extend %photo-configs;
      background: linear-gradient(320deg, rgba(135, 44, 76, 1) 20%, rgba(198, 95, 76, 1) 60%);
      background-size: 400%;
      animation: glowing-rgb 10s linear infinite;
    }
  }
}

// ---------------------------------- //

.hof-page_left-card {
  @include hof-card;
}
