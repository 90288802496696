.input-box {
  position: relative;
  display: inline-block;

  .ui-input {
    width: 100%;
    font-size: 1.4rem;
    border: none;
    border-bottom: 1px solid currentColor;
    background: unset;
    outline: none;
    caret-color: currentColor;
    padding: 0.6rem;
    padding-right: 2rem;

    &:focus ~ label,
    &:valid ~ label {
      top: -0.1rem;
      left: 0.4rem;
      font-size: 1rem;
    }
  }

  label {
    position: absolute;
    top: 50%;
    left: 0.6rem;
    transform: translateY(-50%);
    font-size: 1.4rem;
    color: currentColor;
    opacity: 0.9;
    user-select: none;
    white-space: nowrap;
    pointer-events: none;
    transition: $main-transition;
  }

  i {
    position: absolute;
    top: 50%;
    right: 0.2rem;
    transform: translateY(-50%);
    font-size: clamp(0.8rem, 1vw, 2rem);
    color: currentColor;
    cursor: pointer;
  }
}

// --------------------------------------- //
// themes //
// --------------------------------------- //

.ui-input--default {
  color: $text-color;
}

.ui-input--color-done {
  color: $light-green !important;
}

.ui-input--color-error {
  color: $error-color !important;
}

// --------------------------------------- //
