.f-Msg-Component-Ativo {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(15px);
  box-shadow: -1px 0px 20px 1px black;
  border-left: 1px inset;
  border-image: radial-gradient(circle at top left, rgba(177, 74, 197, 0.2), rgba(102, 9, 121, 0.2), rgba(102, 9, 121, 0.2), rgba(102, 9, 121, 0.2));
  border-image-slice: 1;
  height: 100%;
  width: 400px;
  max-width: 100%;
  top: 0;
  right: 0;
  margin: 0;
  transition: width ease 0.1s;
  z-index: 100;
}

.f-Msg-Component {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 60px;
  margin: 10px;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100;
  border-radius: 50%;
}

.f-Active-Button {
  width: 60px;
  height: 60px;
  border-radius: 50%;
}

.f-section {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 1000;
}

.f-Chat-Component {
  position: relative;
  width: 100%;
  height: 100%;
  transition: all ease 0.1s;
}

.f-Chat-Header {
  display: flex;
  height: 160px;
  align-items: center;
}

.f-Chat-Header-User {
  height: 100%;
  padding: 30px 10px;
}

.user-Gradient-Nick {
  font-size: 2.3rem;
  font-weight: bold;
  font-family: gilroy-medium;
  font-style: italic;
  letter-spacing: 0.15em;
  background: -webkit-linear-gradient(left, #2ac1d8, #ff28f1);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin: 5px 0px;
  max-width: 180px;
}

.user-Status {
  position: relative;
  display: inline-block;
}

.status-ball {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  margin-right: 10px;
  filter: brightness(1.8);
  box-shadow: inset 1px 1px 5px 1px rgba(0, 0, 0, 1), 1px 1px 1px 1px rgba(0, 0, 0, 0.5);
  border: 1px solid rgba(0, 0, 0, 0.2);
}

.dropdown-Status {
  display: none;
  position: absolute;
  min-width: 110px;
  border-radius: 8px;
  background-color: black;
  top: 100%;
  left: -10px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 2000;
}

.user-Status:hover .dropdown-Status {
  display: block;
  z-index: 2000;
}

.dropdown-Status-item {
  display: flex;
  align-items: center;
  margin: 6px;
  z-index: 2000;
}

.dropdown-Status-item:hover {
  cursor: pointer;
  z-index: 2000;
}

.f-Chat-Func-Btn {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px;
  height: 160px;
}

.f-Active-Button-Close {
  border-radius: 50%;
}

.f-Active-Button-Requests {
  border-radius: 50%;
}

.f-Active-Button-Add {
  border-radius: 50%;
}

.f-Chat-Header-UserIMG {
  width: 130px;
  height: 130px;
  border-radius: 50%;
  border: 5px solid var(--purple);
  margin: 10px;
}

.f-box-list {
  height: 82%;
  display: flex;
  overflow-y: auto;
  overflow-x: hidden;
  flex-direction: column;
}

.friend-box {
  width: 100%;
  height: 90px;
  padding: 5px;
  box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.2);
  transition: all ease 0.05s;
  user-select: none;
  z-index: 1001;
}

.friend-box:hover {
  transform: scale(1.01);
  background-color: rgba(55, 4, 50, 0.1);
}

.friendItems {
  display: flex;
  align-items: center;
  z-index: 1001;
  position: relative;
}

.friend-items-description h2 {
  letter-spacing: 5px;
}

.f-Chat-Header-FriendsIMG {
  width: 70px;
  height: 70px;
  margin: 5px;
  margin-right: 10px;
  border-radius: 50%;
  border: 5px solid var(--purple);
  z-index: 1;
}

.friend-items-description {
  margin: 5px;
  height: 70px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  z-index: 1001;
}

.chatComponent {
  background-color: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(15px);
  border-radius: 8px;
  box-shadow: -2px 0px 5px 1px rgba(0, 0, 0, 0.5);
}

.ChatBox {
  width: 0px;
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  padding: 10px 10px 0px 10px;
  transition: all ease 0.3s;
}

.chatPointer {
  position: absolute;
  width: 25px;
  height: 25px;
  right: -20px;
  clip-path: polygon(0 0, 0% 100%, 100% 50%);
}

.MsgAberto {
  min-width: 400px;
  min-height: 220px;
}

.chatComponent form input {
  width: 100%;
  height: 35px;
  font-size: 1.2rem;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  color: black;
  padding: 7px;
}

.chatComponent form input:focus {
  outline: none;
}
