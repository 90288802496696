.editDeckDeckPage {
  width: 100vw;
  height: 85vh;
  display: flex;
  justify-content: center;
  padding: 10px;

  .LeftSideDeckPage {
    width: 75%;
    margin: 5px;
    background: rgba(100, 0, 100, 0.3);
    display: flex;
    border-radius: 15px;
    max-height: 83vh;
    overflow-x: auto;
    overflow-y: hidden;
    position: relative;
    justify-content: space-between;

    .listDeckPage {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      max-height: 51.7vh;
      // min-width: 60%;
      // width: 100%;
      margin-right: 250px;
      border-radius: 20px;
      backdrop-filter: blur(5px);
      flex-wrap: wrap;

      .listDeckPage-item {
        width: 250px;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        margin: 5px;

        .imageDeckPage {
          width: 250px;
          display: flex;
          border-radius: 15px;
          margin-bottom: -315px;
          z-index: 1;
        }
      }
    }

    .listDeckPage-Side {
      display: flex;
      min-width: 260px;
      height: 82vh;
      margin: 5px;
      border-radius: 20px;
      padding: 5px;

      .listDeckPage-item {
        width: 300px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;

        .imageDeckPage {
          width: 250px;
          display: flex;
          border-radius: 15px;
          margin-bottom: -310px;
          z-index: 1;
        }
      }
    }
  }

  .RightSideDeckPage {
    width: 35%;
    height: 83vh;
    min-width: 600px;
    overflow-y: auto;
    overflow-x: hidden;

    .rightDeckCardInfo {
      background: rgba(100, 0, 100, 0.3);
      min-width: 270px;
      width: 100%;
      height: 165px;
      margin: 5px;
      padding: 1rem;
      border-radius: 20px;
      backdrop-filter: blur(5px);
      margin-bottom: 1rem;
    }

    .DisplayCards {
      display: flex;
      align-items: flex-start;
      flex-wrap: wrap;

      .rightDeckCardPage {
        flex: 1;
        background: rgba(100, 0, 100, 0.3);
        min-width: 270px;
        width: 270px;
        min-height: 270px;
        height: 297px;
        margin: 5px;
        padding: 1rem;
        margin-bottom: 1rem;
        border-radius: 20px;
        backdrop-filter: blur(5px);
      }

      .rightDeckCardEdit {
        flex: 1;
        background: rgba(100, 0, 100, 0.3);
        min-width: 270px;
        width: 270px;
        height: 297px;
        margin: 5px;
        padding: 1rem;
        border-radius: 20px;
        backdrop-filter: blur(5px);
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .cardPreview {
    width: 250px;
    border-radius: 15px;
    z-index: 1000;
    position: absolute;
    pointer-events: none;
  }
}
