.swiper {
  width: 100%;
}

.ehPtPv {
  transform: scale(0.7);
}
.sc-crHmcD {
  width: 300px;
  height: 500px;
}

.swipper-slide-img:active {
  transform: scale(0.97);
}

.swipper-slide-img-deck:active {
  transform: scale(0.97);
}

.swiper-pagination-bullet {
  width: 2.8rem;
  height: 0.6rem;
  border-radius: 5px;
  background: #60039e;
}

.swiper-pagination-bullet-active {
  background: $lightblue;
}

.swiper-button-next,
.swiper-button-prev {
  color: $lightblue;
  // &::before {
  //   content: '';
  //   position: absolute;
  //   top: -60px;
  //   left: -60px;
  //   background-color: rgba($color: #000000, $alpha: 1);
  //   width: 120px;
  //   height: 120;
  //   z-index: -1;
  // }
}
