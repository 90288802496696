.checkbox-div {
    &-input {
        display: none;
    }

    &-input:checked~&-label i {
        opacity: 1;
    }

    &-label {
        cursor: pointer;
        width: 1.4rem;
        height: 1.4rem;
        border: 2px solid $lightblue;
        border-radius: 8px;
        background: black;
        margin-right: 1rem;
        position: relative;

        @include extra-small-mobile {
            width: 1.2rem;
            height: 1.2rem;
            border-radius: 5px;
        }

        i {
            position: absolute;
            // top: -2px;
   // left: -2px;
            opacity: 0;
        }
    }

    &-text {
        font-size: clamp(1.2rem, 1.5vw, 2.2rem);

        @include extra-small-mobile {
            font-size: clamp(1rem, 1.5vw, 2.2rem);
        }
    }
}