.c-profile {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 1vw;
  position: relative;

  button {
    display: flex;
    align-items: center;
    font-size: clamp(1rem, 1.2vw, 2rem);
    letter-spacing: 0.2vw;
    position: absolute;
    top: 2vw;
    right: 2vw;
    z-index: 100;
    background: transparent;
    border: none;
    cursor: pointer;
    outline: none;

    @include mobile {
      margin-right: 1.5vw;
    }

    i {
      color: $pink;
      font-size: clamp(1rem, 1.5vw, 2rem);
      margin-left: 5px;
      transition: $cubic-main;
    }

    &:hover i {
      color: $pink2;
    }

    &:active {
      transform: scale(0.9);
    }
  }

  &-avatar {
    height: 100%;
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 5vw;

    @include mobile {
      padding-left: 10vw;
    }

    &-dropzone {
      display: grid;
      place-items: center;
      width: 11vw;
      height: 11vw;
      background: #222;
      border-radius: 50%;
      cursor: pointer;
      position: relative;

      @include mobile {
        width: 23vw;
        height: 23vw;
      }

      i {
        font-size: clamp(1.4rem, 1.5vw, 2rem);

        @include mobile {
          font-size: clamp(0.8rem, 1.5vw, 2rem);
        }
      }
    }

    &-photo {
      position: absolute;
      width: 11vw;
      height: 11vw;
      display: grid;
      place-items: center;
      clip-path: circle();
      // background: red;

      @include mobile {
        width: 28vw;
        height: 28vw;
      }

      img {
        display: block;
        max-width: 100%;
        max-height: 100%;
        object-fit: cover;
      }
    }

    img {
      position: absolute;
      object-fit: contain;
      width: 16vw;
      height: 16vw;
      z-index: 100;
      pointer-events: none;

      @include mobile {
        width: 38vw;
        height: 38vw;
      }
    }

    &-associate {
      position: absolute;
      bottom: 3.5vh;
      z-index: 1000;
      width: 17vw;
      min-width: 160px;
      height: clamp(45px, 7vh, 70px);
      min-height: 45px;
      padding: 0.6rem 0.8rem;
      background: $pink;
      border-radius: 0.8vw;
      display: flex;
      align-items: center;

      &-img {
        width: 30%;
        height: 100%;
        display: grid;
        place-items: center;
        border-radius: 8px;

        img {
          position: relative;
          object-fit: contain;
          height: 100%;
          width: 80%;
        }
      }

      p {
        font-size: clamp(0.6rem, 1vw, 1.1rem);
        white-space: nowrap;
        text-transform: uppercase;
        letter-spacing: 1px;
        line-height: 1;

        @include mobile {}
      }

      h1 {
        font-size: clamp(1.2rem, 1.6vw, 1.8rem);
        line-height: 1.1;
        white-space: nowrap;
      }

      .associate-tolltip {
        background: #e74c3c;
        border-radius: 50%;
        min-width: 1rem;
        min-height: 1rem;
        position: absolute;
        display: grid;
        place-items: center;
        z-index: 9999;
        top: -6px;
        right: -6px;

        &-container {
          position: absolute;
          z-index: 9000;
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  &-infos {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    // margin-left: 4vw;

    @include mobile {
      margin-left: 10vw;
    }

    input {
      width: 12vw;
      min-width: 70px;
      font-size: 1.3rem;
    }

    p {
      font-size: clamp(1rem, 1.5vw, 2.5rem);
      font-family: gilroy-thin;
      text-align: start;
      white-space: nowrap;

      @include mobile {
        font-size: 0.8rem;
      }
    }

    h1 {
      font-size: clamp(2.4rem, 3.5vw, 4rem);
      letter-spacing: 0.4vw;

      @include mobile {
        letter-spacing: 0.8vw;
        font-size: clamp(1.8rem, 3.5vw, 4rem);
      }
    }

    &-results {
      white-space: nowrap;
      margin-bottom: 2vw;
      font-size: clamp(1rem, 1.2vw, 2rem);

      @include mobile {
        font-size: 0.8rem;
      }

      b {
        font-family: gilroy-bold;
        margin: 0 0.8rem;

        @include mobile {
          margin: 0 0.4rem;
        }
      }
    }

    &-social {
      display: flex;
      align-items: center;
      height: 15%;
      margin-top: 0.5rem;
      margin-bottom: -1rem;

      i {
        font-size: clamp(1.2rem, 1.35vw, 1.8rem);
        color: $pink2;
        cursor: pointer;
        margin-right: 1rem;
        transition: $cubic-main;

        @include mobile {
          margin-right: 2.8vw;
          font-size: 1rem;
        }

        &:hover {
          color: $lightblue;
          filter: drop-shadow(2px 4px 8px $lightblue);
        }
      }
    }
  }
}