.profileWidgetPlayer {
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
}

.avatarContainerPlayer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  /* width: 40%; */
  min-width: 240px;
  height: 100%;
  /* background-color: azure; */
}

.avatarContainerPlayer .avatarContainer__bg {
  background: url(../../assets/img/borda_avatar.webp);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  height: 100%;
  min-height: 300px;
  width: 330px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: blue; */
}

/* .avatarContainerPlayer::after {
  content: "HIVE JOGOS";
  width: 15rem;
  height: 2.8rem;
  border-radius: 0.5rem;
  background: rgb(174, 1, 218);
  display: flex;
  justify-content: center;
  align-items: center;
  letter-spacing: 1.5px;
  font-size: 1.6rem;
  font-family: gilroy-thin;
  position: relative;
  bottom: 2.2rem;
} */

.AvatarIMGPlayer {
  width: 45%;
  object-fit: contain;
  border-radius: 50%;
  z-index: -1;
}

.infosProfilePlayer2 {
  width: 30%;
  height: 100%;
  margin-top: 30px;
  margin-left: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  transform: scale(1.2);
}

.playerNamePlayer::selection,
.gradientNick::selection,
.playerResult::selection,
.playerResult b::selection {
  background: var(--pink);
  color: #fff;
}

.playerNamePlayer {
  display: flex;
  align-items: flex-end;
  font-size: 1.2rem;
  font-family: Gilroy-Thin;
  margin-top: 20px;
  height: 32%;
}

.infosProfilePlayer .playerNamePlayer input {
  background: transparent;
  border-radius: 0.45rem;
  outline: none;
  cursor: pointer;
  border: none;
  height: 2rem;
  margin-right: 0.5rem;
  box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.7);
  font-family: gilroy;
  font-size: 0.9rem;
}

.infosProfilePlayer .playerNamePlayer input:focus {
  box-shadow: inset 2px 2px 5px rgba(0, 0, 0, 0.7);
}

.editBtnPlayer {
  background: transparent;
  margin: 0;
  border: none;
  border-radius: 0.55rem;
  height: 2.5rem;
  width: 2.5rem;
  transition: 0.2s;
  cursor: pointer;
  outline: none;
}

.editBtnPlayer:hover {
  transform: scale(1.1);
  /* box-shadow: 2px 2px 5px rgba(0, 0, 0, .7); */
}

.editBtnPlayer:active {
  transform: scale(1.05);
  /* box-shadow: inset 2px 2px 5px rgba(0, 0, 0, .7); */
}

.NickBLock {
  display: flex;
  height: 25%;
  align-items: center;
}

.gradientNickPlayer {
  font-size: 2.5rem;
  font-weight: bold;
  font-family: gilroy-medium;
  font-style: italic;
  letter-spacing: 0.23em;
  background: -webkit-linear-gradient(left, #2ac1d8, #ff28f1);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin: 5px 0px;
}

.paisIMGPlayer {
  height: 1.9rem;
  padding-left: 0.8rem;
  user-select: none;
}

.playerResultPlayer {
  letter-spacing: 0.12em;
  font-size: 1.5rem;
  font-family: gilroy;
}

.playerResultPlayer b {
  font-weight: bold;
}

.social-icons {
  margin: 15px 0;
}
